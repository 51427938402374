import React from "react";
import List from "@material-ui/core/List";
import { getAccuracy } from "../../../helpers";
import RepSummary from "./RepSummary";

export default function MistakeList({ reps, repData }) {
	const mistakeReps = [];
	Object.keys(reps).forEach((rep) => {
		if (getAccuracy(reps[rep]) < 100) mistakeReps.push(rep);
	});

	return (
		<List className="mistake-list">
			{mistakeReps.reverse().map((rep) => (
				<RepSummary key={rep} info={repData[rep]} />
			))}
		</List>
	);
}
