import { lastElement, randomElement } from "../../../helpers";
import { suits } from "../../../constants";
import { shuffle } from "lodash";

export default function randomizeHandSuits(hand) {
	const handArr = hand[0].split("");
	if (handArr.length <= 3) {
		//quick check for if hand is NLHE
		const suit1 = randomElement(suits);
		let suit2;
		if (lastElement(handArr) === "s") {
			suit2 = suit1;
		} else {
			suit2 = randomElement(suits.filter((suit) => suit != suit1));
		}
		return [hand[0], handArr[0] + suit1, handArr[1] + suit2];
	} else {
		const handString = hand[0];
		const cardArray = [];
		const suitArray = shuffle(suits);
		let currentSuit = suitArray[0];
		let changeSuit = true;
		// randomly select suits for cards based on suitedness brackets
		// // cases: rainbow, 1 suited, 2 suited
		for (let i = 0; i < handString.length; i++) {
			if (handString.charAt(i) === "(") changeSuit = false;
			else if (handString.charAt(i) === ")") changeSuit = true;
			else cardArray.push(`${handString[i]}${currentSuit}`);
			if (changeSuit) currentSuit = suitArray[suitArray.indexOf(currentSuit) + 1];
		}
		return [hand[0], ...shuffle(cardArray)];
	}
}
