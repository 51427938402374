import getSeatNamesFromNumberOfSeats from "../../Shared/functions/getSeatNamesFromNumberOfSeats";
import getStarterActionSummary from "../../Shared/functions/getStarterActionSummary";
import getPreviousAction from "../../Shared/functions/getPreviousAction";
import getChipRaiseAmount from "../../Shared/functions/getChipRaiseAmount";
import getRaisePercent from "../../Shared/functions/getRaisePercent";
import { getPositionFromAction } from "../../../helpers";
import { cloneDeep, round } from "lodash";
import getAmountCommittedFromActionSummary from "../../Shared/functions/getAmountCommittedFromActionSummary";
import getPriceToCall from "./getPriceToCall";

// also add this as a parameter, maybe: repID: string | null = null
// if that flickering bug is an issue but I think it won't be
export default function getActionSummaryFrames(treeInfo: Record<string, any>, spot: string) {
	const actionSummaryFrames: Array<actionSummary> = [];
	let raiseLevel = treeInfo.straddle ? 3 : 2;
	let playersInHand: string[] = getSeatNamesFromNumberOfSeats(treeInfo.seats);
	let actionSummary = getStarterActionSummary(treeInfo);
	let priceToCall = getPriceToCall(actionSummary);
	const initialPot = treeInfo.ante * treeInfo.seats + getAmountCommittedFromActionSummary(actionSummary);
	let potSize = initialPot;
	let actionArray = spot.split(",");
	//@ts-ignore
	if (treeInfo.straddle) playersInHand.push(playersInHand.shift());
	actionSummaryFrames.push(cloneDeep(actionSummary));
	for (let i = 0; i < actionArray.length; i++) {
		const pos = getPositionFromAction(actionArray[i]);
		const action = actionArray[i].replace(pos, "");
		const actionStr = actionArray.slice(0, i).join();
		actionSummary[pos].currentAction = actionStr ? actionStr + "," + pos + action : pos + action;
		if (getPreviousAction(actionStr + "," + pos)) {
			actionSummary[pos].previousAction = getPreviousAction(actionStr + "," + pos);
		}
		const folds = [];
		Object.keys(actionSummary).forEach((p) => (actionSummary[p].active = p === pos ? true : false));
		// Fold out positions
		for (let j = 0; j < playersInHand.length; j++) {
			if (!(pos === playersInHand[j])) {
				actionSummary[playersInHand[j]].folded = true;
				folds.push(playersInHand[j]);
			} else {
				// 1) players in the folds array need to be removed from the playersInHand array
				folds.forEach((pos) => playersInHand.splice(playersInHand.indexOf(pos), 1));
				// 2) playersInHand needs to be sorted in order of who would be acting next, ie. the player who just acted should be going last
				//@ts-ignore
				playersInHand.push(playersInHand.shift());
				// 3) the player who took the action needs to be updated in the actionSummary dictionary, with raiseLevel (how many chips to display visually), raiseAmount
				// 3a) action is a call, meaning the raiseLevel doesn't increase but potsize does
				if (action === "1") {
					potSize = potSize + priceToCall - (actionSummary[pos].amountCommitted || 0);
					actionSummary[pos].amountCommitted = priceToCall;
				}
				// 3b) action is a raise
				else if (action !== "") {
					raiseLevel += 1;
					let raiseAmount;
					// 3bi) action is a jam
					if (action === "3") raiseAmount = treeInfo.stackDepth - priceToCall;
					// 3bii) action is a minraise, half-pot raise, or pot raise
					else if (action === "5") raiseAmount = priceToCall;
					else if (action === "4") raiseAmount = round((potSize + priceToCall - (actionSummary[pos].amountCommitted || 0)) * 0.5, 1);
					else if (action === "2") raiseAmount = round(potSize + priceToCall - (actionSummary[pos].amountCommitted || 0), 1);
					// 3biii) action is a % based raise
					else if (action.startsWith("40") && action.length !== 2) {
						const raisePercent = getRaisePercent(action);
						raiseAmount = round((potSize + priceToCall - (actionSummary[pos].amountCommitted || 0)) * raisePercent, 1);
					}
					// 3biv) action is chip amount raise
					else {
						raiseAmount = getChipRaiseAmount(action);
						raiseAmount = raiseAmount * treeInfo.chipSize; // Normalizes chip raise amount to SB
					}
					potSize = potSize + priceToCall + raiseAmount - (actionSummary[pos].amountCommitted || 0);
					priceToCall = priceToCall + raiseAmount;
					actionSummary[pos].amountCommitted = priceToCall;
					actionSummary[pos].raiseLevel = raiseLevel;
				}
				// 3c) action = "", meaning this is the final loop ie. the action being tested. Since this will always happen, it can be the final case. Actually... no action needed for this case
				break;
			}
		}
		actionSummaryFrames.push(cloneDeep(actionSummary)); // prob need to clone this...
		// if (repID === this.state.repID) this.setPlayerSpotArray(spot, actionSummary); // The 'if' here is to fix the graphics from being too jumpy if the previous hand ended fast
	}
	return actionSummaryFrames;
}
