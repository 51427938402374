import React from "react";

export default function PLOSearchField({ searchQuery, handleSearch, handleXClick }) {
	return (
		<div className="plo-search-container">
			<input
				autoFocus
				className="plo-search-field"
				value={searchQuery}
				onChange={(e) => handleSearch(e.target.value)}
				onDoubleClick={(e) => e.target.select()}
			/>
			<button className="plo-x-button" onClick={handleXClick}>
				x
			</button>
		</div>
	);
}
