import { _SRP_IP, _SRP_OOP, _3BP_IP, _3BP_OOP, _4BP, _5BP } from "./PIO_EXPORT_AFFIXES";
import { round } from "lodash";

const helperObj = {
	SRP_IP: _SRP_IP,
	SRP_OOP: _SRP_OOP,
	"3BP_IP": _3BP_IP,
	SQZ_IP: _3BP_IP,
	"3BP_OOP": _3BP_OOP,
	SQZ_OOP: _3BP_OOP,
	"4BP": _4BP,
	"Cold 4Bet": _4BP,
	"5BP": _5BP,
	"Cold 5Bet": _5BP,
	Other: _4BP,
};

export default function generatePIOExportFile(spot, ranges, IPisRaiser, pot, effStacks) {
	let raiser = IPisRaiser ? "_IP" : "_OOP";
	if (["4BP", "Cold 4Bet", "5BP", "Cold 5Bet", "Other"].includes(spot.potType)) raiser = "";
	//prettier-ignore
	let fileContents = 
`#Type#NoLimit
#Range0#${ranges[0]}
#Range1#${ranges[1]}
#Pot#${round(pot,0)}
#EffectiveStacks#${round(effStacks, 0)}
${helperObj[`${spot.potType}${raiser}`]}
`;
	return fileContents;
}
