import React from "react";
import RangeChart from "./RangeChart";
import { UserContext } from "../../UserContext";
import getActionDescription from "../functions/getActionDescription";
import { round } from "lodash";
import onMobile from "../../Mobile/onMobile";
import onTouchScreen from "../../Mobile/onTouchScreen";
import getSeatNamesFromNumberOfSeats from "../../Shared/functions/getSeatNamesFromNumberOfSeats";

let lastTap;
export default class RangeDisplay extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			spotFrequencies: [],
			onMobile: onMobile(),
			onTouchScreen: onTouchScreen(),
		};
	}

	setSpotFrequencies = (freq) => this.setState({ spotFrequencies: [...this.state.spotFrequencies, freq] });

	getRangeCharts = () => {
		const ranges = this.props.info.ranges;
		const rangeCharts = [];
		ranges.forEach((range) => {
			rangeCharts.push(
				<RangeChart
					key={range}
					treeID={this.props.info.treeID}
					action={range}
					setSpotFrequencies={this.setSpotFrequencies}
					treeInfo={this.props.info.treeInfo}
				/>
			);
		});
		return rangeCharts;
	};

	getFoldFrequency = (spotFrequencies) => round(spotFrequencies.reduce((fold, freq) => (fold -= freq), 1) * 100, 1);

	render() {
		let { position, actionString } = this.props.info;
		const straddlePos = this.props.info.treeInfo.straddle ? getSeatNamesFromNumberOfSeats(this.props.info.treeInfo.seats)[0] : null;
		const posLabel = straddlePos === position ? "Straddle" : position.toUpperCase();
		const title = posLabel + (actionString !== "" ? " Facing " + getActionDescription(actionString, straddlePos) : " Open");
		const rangeCharts = this.getRangeCharts();
		return (
			<div className="range-display" onContextMenu={this.state.onTouchScreen ? (e) => e.preventDefault() : this.props.handleRightClick}>
				<div className="range-display-tree-label">{this.context.treePermissions[this.props.info.treeID]}</div>
				<div className="range-display-title">{title}</div>
				<div className="range-display-subtitle">{`Fold - ${this.getFoldFrequency(this.state.spotFrequencies)}%`}</div>
				{rangeCharts}
				<button className="rd-exit-button" onClick={this.props.handleRightClick}>
					x
				</button>
			</div>
		);
	}
}
