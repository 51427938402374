// React stuff
import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../UserContext";
// Range Functions
import fetchRange from "../../Shared/functions/fetchRange";
import getPreviousActionFromSpot from "../../Shared/functions/getPreviousAction";
// Material UI
// Constants and Helpers
import { cloneDeep, round } from "lodash";
import { allHandsMatrix } from "../../../constants";
import MiniRangeCell from "./MiniRangeCell";

export default function MiniRangeChart({ tree, action, coordinates = null, isMini = false, highlight = null }) {
	const [range, setRange] = useState(null);
	const [normRange, setNormRange] = useState(null);

	// fetch range data on component loading
	useEffect(() => {
		getRange();
	}, []);
	async function getRange() {
		let range = await fetchRange(tree, action);
		setNormRange(cloneDeep(range));
		const previousAction = getPreviousActionFromSpot(action);
		if (previousAction) {
			const prevData = await fetchRange(tree, previousAction);
			for (const hand in range) {
				range[hand] = range[hand] / prevData[hand];
			}
		}
		setRange(range);
	}

	// formats the frequency display to match range conventions
	const roundFreq = (freq) => {
		if (!freq) return "0";
		else if (freq.toString() === "1.0") return freq;
		else return round(freq, 2);
	};

	const handleMouseEnter = (e, freq) => (e.target.textContent = freq.toString().replace("0.", "."));
	const handleMouseLeave = (e, hand) => (e.target.textContent = hand);

	if (range) {
		let style;
		if (coordinates) style = { position: "fixed", left: coordinates.left - 50 + "px", top: coordinates.top - 25 + "px" };
		else style = { position: "relative", display: "inline-block" };
		return (
			<div className={"range-chart mini"} style={style}>
				{allHandsMatrix.map((row, index) => (
					<div className="range-row" key={index}>
						{row.map((hand) => {
							const freq = roundFreq(range[hand]);
							const classes = `range-cell mini ${highlight === hand ? "rc-focus" : ""}`;
							return (
								<MiniRangeCell
									onMouseEnter={(e) => handleMouseEnter(e, freq)}
									onMouseLeave={(e) => handleMouseLeave(e, hand)}
									key={hand}
									classes={classes}
									hand={hand}
									freq={freq}
									isMini={true}
								/>
							);
						})}
					</div>
				))}
			</div>
		);
	} else return <div></div>;
}
