import { func } from "prop-types";
import getSeatNamesFromNumberOfSeats from "./components/Shared/functions/getSeatNamesFromNumberOfSeats";

//String functions
export const removeNumbers = (string) => string.replace(/[0-9]/g, "");
export const removeNonDigits = (string) => string.replace(/\D/g, "");

export const getPositionFromAction = (string) => {
	const seats = getSeatNamesFromNumberOfSeats(9);
	let pos = "";
	for (let i = seats.length - 1; i >= 0; i--) {
		if (string.includes(seats[i])) {
			pos = seats[i];
			break;
		}
	}
	return pos;
};

export const removePositionText = (string) => {
	return string.replace(getPositionFromAction(string), "");
};

export const shuffle = (array) => {
	let currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex != 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
};

export const isEmpty = (thing) => {
	if (Array.isArray(thing)) return thing.length === 0;
	else return Object.keys(thing).length === 0;
};

// Array functions
export const randomElement = (array) => array[Math.floor(Math.random() * array.length)];
export const lastElement = (array) => array[array.length - 1];
export const removeDuplicates = (array) => [...new Set(array)];
export const hasDuplicates = (array) => new Set(array).size !== array.length;

export const hasTriples = (array) => {
	const set = removeDuplicates(array);
	return set.some((item) => countOccurrences(array, item) === 3);
};
export const countOccurrences = (array, value) => {
	var count = 0;
	array.forEach((v) => v === value && count++);
	return count;
};

// Object functions
export const getMaxValueKey = (obj) => Object.keys(obj).reduce((a, b) => (obj[a] > obj[b] ? a : b));
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

//todo: refactor this into a file in Shared
export const getPositionsFromActionArray = (array) => {
	return array.reduce((acc, elmt) => {
		const pos = getPositionFromAction(elmt);
		return removeDuplicates([...acc, pos]);
	}, []);
};

export const positionIsRepeated = (array) => (getPositionsFromActionArray(array).length !== array.length ? true : false);

export function getDate() {
	var date = new Date();
	var options = { dateStyle: "medium", timeStyle: "long", timeZone: "EST", hour12: false };
	return date.toLocaleString("en-US", options);
}

export function getAccuracy(score) {
	const scoreArr = score.split("/");
	const accuracy = Math.round((scoreArr[0] / scoreArr[1]) * 1000) / 10;
	return accuracy;
}

export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

// grabbed from: https://stackoverflow.com/questions/7128675/from-green-to-red-color-depend-on-percentage/7128796
const accuracyPercentColors = [
	{ pct: 0.0, color: { r: 235, g: 0, b: 0 } },
	{ pct: 0.1, color: { r: 235, g: 0, b: 0 } },
	{ pct: 0.7, color: { r: 220, g: 200, b: 0 } },
	{ pct: 0.9, color: { r: 0, g: 230, b: 0 } },
	{ pct: 1.0, color: { r: 0, g: 230, b: 0 } },
];

const masteryPercentColors = [
	{ pct: 0.0, color: { r: 255, g: 255, b: 255 } },
	{ pct: 1.0, color: { r: 0, g: 200, b: 255 } },
];

const aggressionPercentColors = [
	{ pct: -10.0, color: { r: 255, g: 150, b: 200 } },
	{ pct: -0.1, color: { r: 255, g: 230, b: 240 } },
	{ pct: 0.0, color: { r: 180, g: 255, b: 180 } },
	{ pct: 0.1, color: { r: 255, g: 225, b: 200 } },
	{ pct: 10.0, color: { r: 255, g: 125, b: 0 } },
];

export function getColorFromPercentage(pct, type = "accuracy") {
	let percentColors;
	if (type === "accuracy") percentColors = accuracyPercentColors;
	else if (type === "mastery") percentColors = masteryPercentColors;
	else if (type === "aggression") percentColors = aggressionPercentColors;

	for (var i = 1; i < percentColors.length - 1; i++) {
		if (pct < percentColors[i].pct) {
			break;
		}
	}
	var lower = percentColors[i - 1];
	var upper = percentColors[i];
	var range = upper.pct - lower.pct;
	var rangePct = (pct - lower.pct) / range;
	var pctLower = 1 - rangePct;
	var pctUpper = rangePct;
	var color = {
		r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
		g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
		b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
	};
	return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
}

export default function makeURL(inputURL) {
	if (inputURL.substring(0, 7) != "http://" && inputURL.substring(0, 8) != "https://") inputURL = "http://" + inputURL;

	return inputURL;
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
});
