// React & DB Stuff
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UserContext } from "./UserContext";
import base from "../base";
import firebase from "firebase";
// UI
import Loading from "./Loading";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
// Components to Route to
import Dashboard from "./Dashboard/Dashboard";
import Upload from "./Upload/components/Upload";
import DRD from "./DRD/components/DRD";
import RangeTrainer from "./RangeTrainer/components/RangeTrainer";
import TrainingData from "./TrainingData/components/TrainingData";
import TreeManager from "./TreeManager/components/TreeManager";
import Settings from "./Settings/components/Settings";
import Feedback from "./Feedback";
// Login/authentication
import Login from "./Splash";
import { getDate } from "../helpers";
// Admin functions
import getUserLastLogin from "./Admin/getUserLastLogin";
import getTreeNamesAndIDs from "./Admin/getTreeNamesAndIDs";
import verifyUser from "./Admin/verifyUser";
import addTreeToUser from "./Admin/addTreeToUser";
import removeTreeFromUser from "./Admin/removeTreeFromUser";
import MassPioExport from "./Tools/MassPioExport";
import Tools from "./Tools/Tools";
import "normalize.css";
import verifyBasicUser from "./Admin/verifyBasicUser";
import deleteTree from "./Admin/deleteTree";
import Accounting from "./Accounting/components/Accounting";
import sendVerificationRequest from "./VerificationRequest";

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			userIsLoggedIn: "pending",
			userIsVerified: "pending",
			userInfo: {
				name: null,
				uid: null,
				treePermissions: null,
			},
			currentTrees: [],
		};
	}

	async componentDidMount() {
		firebase.auth().onAuthStateChanged(async (user) => {
			firebase.analytics().logEvent("login");
			if (user) {
				let previousLogin = await base.fetch(`users/${user.uid}/lastLogin`, { context: this });
				// if this is the first email, verify the user and then send me an email letting me know
				if (!Object.keys(previousLogin).length) {
					base.post(`users/${user.uid}/verified`, { data: true });
					sendVerificationRequest(user.uid, user.displayName);
				}
				base.update(`users/${user.uid}`, {
					data: { name: user.displayName, email: user.email, lastLogin: getDate() },
				});
				base.update(`userList`, {
					data: { [user.uid]: user.displayName },
				});
				this.setState({ userInfo: { name: user.displayName, uid: user.uid }, userIsLoggedIn: true });
				// Check if user has been verified
				base.fetch(`users/${user.uid}/verified`, { context: this }).then(async (userIsVerified) => {
					if (userIsVerified === true) {
						let treePermissions, currentTree, currentTrees, settings;
						// TODO: promise.all these
						await base.fetch(`treePermissions/${user.uid}`, { context: this }).then((tpData) => {
							treePermissions = tpData;
						});
						await base.fetch(`users/${user.uid}/currentTrees`, { context: this }).then((ctsData) => {
							currentTrees = ctsData;
						});
						this.treesRef = base.syncState(`users/${user.uid}/currentTrees`, {
							context: this,
							state: "currentTrees",
							defaultValue: [],
							then: this.setState({ userInfo: { ...this.state.userInfo, treePermissions }, currentTrees, loading: false, userIsVerified }),
						});
						this.settingsRef = base.syncState(`users/${user.uid}/settings`, {
							context: this,
							state: "settings",
							defaultValue: {
								rtRngLowToHigh: false,
								rtFastDeal: false,
								rtTightHandSelect: false,
								rtOptions: {},
								rtShowPrevFreq: "<100", // "always", "<100", "never"
								rtProportionalDeal: false,
								rtProportionalScenarios: false,
								drdLargeDisplays: false,
								multiTreeSelect: false,
							},
						});
					} else this.setState({ userIsVerified: false });
				});
			} else this.setState({ userIsLoggedIn: false });
		});
	}

	componentWillUnmount() {
		this.treesRef && base.removeBinding(this.treesRef);
		this.settingsRef && base.removeBinding(this.settingsRef);
	}

	//TODO: handleTreeAdd, handleTreeRemove, rework handleTreeChange to function when there is 1 tree in the array
	handleTreeChange = (tree) => this.setState({ currentTree: tree });
	handleTreesChange = (trees) => this.setState({ currentTrees: trees });
	handleSettingsUpdate = (property, value) => {
		const settings = this.state.settings;
		settings[property] = value;
		this.setState({ settings });
	};

	logout = async () => {
		await firebase.auth().signOut();
		this.setState({
			userIsLoggedIn: false,
			userIsVerified: false,
			userInfo: {
				name: null,
				uid: null,
				treePermissions: null,
			},
			currentTrees: [],
		});
		return true;
	};

	// Dev/Admin functions //

	getUserLastLogin = () => getUserLastLogin(this.state.userInfo.uid);
	getTreeNamesAndIDs = (alphaSort = true) => getTreeNamesAndIDs(this.state.userInfo.uid, alphaSort);
	verifyUser = (userToVerify) => verifyUser(this.state.userInfo.uid, userToVerify);
	verifyBasicUser = (userToBasicify) => verifyBasicUser(this.state.userInfo.uid, userToBasicify);
	addTreeToUser = (user, treeID, treeName) => addTreeToUser(this.state.userInfo.uid, user, treeID, treeName);
	removeTreeFromUser = (user, treeID) => removeTreeFromUser(this.state.userInfo.uid, user, treeID);
	deleteTree = (treeID, deleteReps = false, trackDeletion = true) => deleteTree(this.state.userInfo.uid, treeID, deleteReps, trackDeletion);

	// ------------------ //

	render() {
		window.dpm = this;
		if (!this.state.userIsLoggedIn) {
			return <Login />;
		} else if (this.state.userIsVerified === false) {
			return <Loading pulse={false} />;
		}
		if (!this.state.loading) {
			return (
				<Router>
					<UserContext.Provider
						value={{
							...this.state.userInfo,
							currentTrees: this.state.currentTrees,
							handleTreeChange: this.handleTreeChange,
							handleTreesChange: this.handleTreesChange,
							settings: this.state.settings,
							handleSettingsUpdate: this.handleSettingsUpdate,
						}}>
						<div id="body-container">
							<NavBar logout={this.logout} />
							<SideBar />
							<div id="main-content">
								<Switch>
									<Route exact path="/">
										<Dashboard />
									</Route>
									{/* <Route path="/tabletest">
										<TableViewTest />
									</Route> */}
									{/* <Route path="/treeselectortest">
										<MultiTreeSelector />
									</Route> */}
									<Route path="/upload">
										<Upload />
									</Route>
									<Route path="/drd">
										<DRD />
									</Route>
									<Route path="/trainer">
										<RangeTrainer />
									</Route>
									<Route path="/data">
										<TrainingData />
									</Route>
									<Route path="/trees">
										<TreeManager />
									</Route>
									<Route path="/feedback">
										<Feedback />
									</Route>
									<Route path="/settings">
										<Settings />
									</Route>
									<Route path="/tools/pio-mass-export">
										<MassPioExport />
									</Route>
									<Route path="/tools">
										<Tools />
									</Route>
									<Route path="/accounting">
										<Accounting />
									</Route>
								</Switch>
							</div>
						</div>
					</UserContext.Provider>
				</Router>
			);
		}
		return <Loading pulse={false} />;
	}
}

export default App;
