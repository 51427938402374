// React stuff
import React, { useState } from "react";
// Range Functions
// Material UI
// Constants and Helpers
import { actionNames } from "../../../constants";
import { lastElement, removePositionText } from "../../../helpers";
import { round } from "lodash";

export default function PLOTitleBar({ index, action, frequency, sortBy, handleSortChange }) {
	const actionCode = removePositionText(lastElement(action.split(",")));
	const actionName = actionNames[actionCode] ? actionNames[actionCode] : "Raise";

	const isSortFocus = index === sortBy.actionIndex ? true : false;

	return (
		<div className={`plo-header-div`}>
			<h5 className="plo-header">{`${actionName} - ${round(frequency * 100, 1)}%`}</h5>
			<div className={`plo-subheader`}>
				<h5 className={`${isSortFocus && sortBy.criteria === "ev" ? `plo-sort-focus` : ``}`} onClick={() => handleSortChange("ev", index)}>
					EV
				</h5>
				<h5 className={`${isSortFocus && sortBy.criteria === "f" ? `plo-sort-focus` : ``}`} onClick={() => handleSortChange("f", index)}>
					Freq
				</h5>
			</div>
		</div>
	);
}
