import React from "react";

export default function PLOFilterButtons({ filters, handleFilterSet }) {
	return (
		<div className="plo-filters-container">
			<h4 className="plo-filter-header">Filters</h4>
			<h5 className="plo-filter-header">Pairs</h5>
			<button className={`plo-filter-btn ${filters.includes("1p") ? "clicked" : ""}`} onClick={() => handleFilterSet("pair", "1p")}>
				One Pair
			</button>
			<button className={`plo-filter-btn ${filters.includes("2p") ? "clicked" : ""}`} onClick={() => handleFilterSet("pair", "2p")}>
				Two Pair
			</button>
			<button className={`plo-filter-btn ${filters.includes("0p") ? "clicked" : ""}`} onClick={() => handleFilterSet("pair", "0p")}>
				No Pairs
			</button>
			<button className={`plo-filter-btn ${filters.includes("trips") ? "clicked" : ""}`} onClick={() => handleFilterSet("pair", "trips")}>
				Trips
			</button>
			<h5 className="plo-filter-header">Suitedness</h5>
			<button
				className={`plo-filter-btn ${filters.includes("ds") ? "clicked" : ""}`}
				onClick={() => handleFilterSet("suit", "ds")}
				title="Double Suited">
				<div className="plo-card red">X</div>
				<div className="plo-card red">X</div>
				<div className="plo-card black">X</div>
				<div className="plo-card black">X</div>
			</button>
			<button
				className={`plo-filter-btn ${filters.includes("2s") ? "clicked" : ""}`}
				onClick={() => handleFilterSet("suit", "2s")}
				title="Two of a Suit">
				<div className="plo-card red">X</div>
				<div className="plo-card red">X</div>
				<div className="plo-card black">X</div>
				<div className="plo-card blue">X</div>
			</button>
			<button
				className={`plo-filter-btn ${filters.includes("3s") ? "clicked" : ""}`}
				onClick={() => handleFilterSet("suit", "3s")}
				title="Three of a Suit">
				<div className="plo-card red">X</div>
				<div className="plo-card red">X</div>
				<div className="plo-card red">X</div>
				<div className="plo-card black">X</div>
			</button>
			<button
				className={`plo-filter-btn ${filters.includes("rainbow") ? "clicked" : ""}`}
				onClick={() => handleFilterSet("suit", "rainbow")}
				title="Rainbow">
				<div className="plo-card red">X</div>
				<div className="plo-card black">X</div>
				<div className="plo-card blue">X</div>
				<div className="plo-card green">X</div>
			</button>
			<button
				className={`plo-filter-btn ${filters.includes("mono") ? "clicked" : ""}`}
				onClick={() => handleFilterSet("suit", "mono")}
				title="Monocolour">
				<div className="plo-card red">X</div>
				<div className="plo-card red">X</div>
				<div className="plo-card red">X</div>
				<div className="plo-card red">X</div>
			</button>
		</div>
	);
}
