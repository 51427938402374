import { round } from "lodash";

const roundActionFrequencies = (actionFrequencies) => {
	let roundedActionFrequencies = {};
	let cumulativeFrequency = 0;
	for (const action in actionFrequencies) {
		roundedActionFrequencies[`${action}`] = round(actionFrequencies[`${action}`], 1);
		cumulativeFrequency += roundedActionFrequencies[`${action}`];
	}
	if (cumulativeFrequency > 1) return actionFrequencies;
	else return roundedActionFrequencies;
};

export default roundActionFrequencies;
