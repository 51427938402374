import React from "react";
import chipTooltipImg from "../../../css/images/chip-tooltip.jpg";
import { Tooltip } from "../../Tooltip";

class UploadDetails extends React.Component {
	render() {
		return (
			<div>
				<form className="upload-form" autoComplete="off" onSubmit={this.props.submitDetails}>
					<h2 className="page-heading">Step One: Input tree info </h2>
					<div className="upload-div">
						<label htmlFor="tree-name">Monker Filename</label>
						<input type="text" id="tree-name" value={this.props.monkerFilename} onChange={this.props.handleMonkerFilenameChange} />
					</div>
					<div className="upload-div">
						<label htmlFor="tree-name">Tree Name</label>
						<input type="text" id="tree-name" value={this.props.treeName} onChange={this.props.handleNameChange} />
					</div>

					<div className="upload-div">
						<label htmlFor="rake">Rake Structure</label>
						<input type="text" id="rake" className="upload-meta" defaultValue="" onChange={(e) => this.props.handleMetaChange(e, "rake")} />
					</div>

					<div className="upload-div">
						<label htmlFor="game-type">Hold'em or PLO?</label>
						<select id="game-type" defaultValue="NLHE" onChange={this.props.handleGameTypeChange}>
							<option value="NLHE">Hold'em</option>
							<option value="PLO">PLO</option>
						</select>
					</div>

					<div className="upload-div">
						<label htmlFor="number-of-players">Number of players (6-max, Full-ring)</label>
						<input type="number" id="number-of-players" defaultValue="6" min="2" max="9" onChange={this.props.handleSeatsChange} />
						<Tooltip text="7-9 handed not yet implemented" width={200} />
					</div>

					<div className="upload-div">
						<label htmlFor="stack-depth">Stack Depth (in BBs)</label>
						<input type="number" id="stack-depth" defaultValue="100" min="2" onChange={this.props.handleDepthChange} />
					</div>

					<div className="upload-div">
						<label htmlFor="straddle">Straddle?</label>
						<select id="straddle" defaultValue="false" onChange={this.props.handleStraddleChange}>
							<option value="false">No</option>
							<option value="true">Yes</option>
						</select>
						<label htmlFor="ante">Ante (in SBs)</label>
						<input type="number" id="ante" defaultValue="0" min="0" step="0.01" onChange={this.props.handleAnteChange} />
					</div>

					<div className="upload-div">
						<label htmlFor="chip-size">Monker chip size</label>
						<select id="chip-size" defaultValue="0.2" onChange={this.props.handleChipChange}>
							<option value="0.02">0.02 SB (SB = 50 Chips)</option>
							<option value="0.2">0.2 SB (SB = 5 Chips)</option>
							<option value="0.5">Half SB (SB = 2 Chips)</option>
							<option value="1">SB (SB = 1 Chip)</option>
							<option value="2">BB (SB = 0.5 Chips)</option>
						</select>
						<Tooltip text="How much one 'chip' in monker represents (most commonly sb)" img={chipTooltipImg} width={200} />
					</div>

					<div className="upload-div">
						<label htmlFor="open-size">Open Size</label>
						<input
							type="text"
							id="open-size"
							className="upload-meta"
							defaultValue={this.props.openSize}
							onChange={(e) => this.props.handleMetaChange(e, "openSize")}
						/>
					</div>

					<div className="upload-div">
						<label htmlFor="private">Private</label>
						<input type="checkbox" id="private" onChange={this.props.handlePrivateChange}></input>
						<Tooltip text="Tree will not be visible to other DPM users" />
					</div>

					<input type="submit" value="Next Step" className="upload-submit" />
				</form>
			</div>
		);
	}
}

export default UploadDetails;
