import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import { isEmpty } from "../../../helpers";

export default function TreeInfoPane({ treeInfoPaneOpen, treesInfo, metadata }) {
	const context = useContext(UserContext);
	let mkrFilenames, depths, openSizes, site, stake, rake, ante;

	if (!isEmpty(context.currentTrees) && !isEmpty(treesInfo)) {
		mkrFilenames = metadata?.map((tree, i) => <div key={`tp-${i}`}>{tree.monkerFilename}</div>);
		depths = treesInfo.map((tree) => `${tree.stackDepth / 2}bb`).join(", ");
		openSizes = metadata?.map((tree) => tree.openSize).join(", ");
		rake = metadata?.[0].rake ? metadata?.[0].rake : "-";
		ante = treesInfo?.[0].ante ? `${treesInfo[0].ante}sb` : "-";
	}

	return (
		<div className={treeInfoPaneOpen && !isEmpty(context.currentTrees) ? "drd-tree-info-pane" : "display-none"}>
			<div>Monker Filenames: {mkrFilenames}</div>
			<div>Depths: {depths}</div>
			<div>Open Sizes: {openSizes}</div>
			<div>Rake Structure: {rake}</div>
			<div>Ante? {ante}</div>
		</div>
	);
}
