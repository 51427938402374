import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

export default function PioCopySuccessSnackBar({ copySuccess, setCopySuccess }) {
	const handleClose = (e, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setCopySuccess(false);
	};

	return (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={copySuccess}
			autoHideDuration={2200}
			onClose={handleClose}
			message="PIO format range copied to clipboard"
		/>
	);
}
