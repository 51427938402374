import base from "../../../base";

const fetchTreeManagerColumnData = async (userTrees, publicTrees) => {
	const rows = { user: [], public: [] };

	const sortedUserTrees = [];
	const sortedPublicTrees = [];

	for (const id in userTrees) sortedUserTrees.push([id, userTrees[id]]);
	sortedUserTrees.sort((a, b) => a[1].localeCompare(b[1]));
	for (const id in publicTrees) sortedPublicTrees.push([id, publicTrees[id]]);
	sortedPublicTrees.sort((a, b) => a[1].localeCompare(b[1]));

	const userKeys = userTrees ? sortedUserTrees.map((tree) => tree[0]) : [];
	const allPublicKeys = sortedPublicTrees.map((tree) => tree[0]);
	const availablePublicKeys = allPublicKeys.filter((key) => !userKeys.includes(key));

	const userPromises = [];
	const publicPromises = [];

	userKeys.forEach((key) => {
		userPromises.push(key);
		userPromises.push(base.fetch(`trees/${key}/metadata`, { context: this }));
		userPromises.push(base.fetch(`trees/${key}/treeInfo`, { context: this }));
	});

	availablePublicKeys.forEach((key) => {
		publicPromises.push(key);
		publicPromises.push(base.fetch(`trees/${key}/metadata`, { context: this }));
		publicPromises.push(base.fetch(`trees/${key}/treeInfo`, { context: this }));
	});

	const resolvedUserPromises = await Promise.all(userPromises);
	const resolvedPublicPromises = await Promise.all(publicPromises);

	for (let i = 0; i < resolvedUserPromises.length; i = i + 3) {
		const id = resolvedUserPromises[i];
		let { treeName, openSize, stake, site, rake } = resolvedUserPromises[i + 1]; // metadata
		const privateTree = resolvedUserPromises[i + 1].public ? "No" : "Yes";
		let { stackDepth, ante, seats, gameType, straddle } = resolvedUserPromises[i + 2]; // treeinfo
		stackDepth = `${stackDepth / 2}bb`;
		ante = ante ? `${ante}sb` : "-";
		straddle = straddle ? "Yes" : "No";
		rows.user.push({
			id,
			treeName,
			alias: userTrees[id],
			stackDepth,
			openSize,
			stake,
			site,
			ante,
			rake,
			players: seats,
			gameType,
			straddle,
			privateTree,
		});
	}

	for (let i = 0; i < resolvedPublicPromises.length; i = i + 3) {
		const id = resolvedPublicPromises[i];
		let { treeName, openSize, stake, site, rake } = resolvedPublicPromises[i + 1]; // metadata
		let { stackDepth, ante, seats, gameType, straddle } = resolvedPublicPromises[i + 2]; // treeinfo
		stackDepth = `${stackDepth / 2}bb`;
		ante = ante ? `${ante}sb` : "-";
		straddle = straddle ? "Yes" : "No";
		rows.public.push({ id, treeName, stackDepth, openSize, stake, site, ante, rake, players: seats, gameType, straddle });
	}

	return rows;
};

export default fetchTreeManagerColumnData;
