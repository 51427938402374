import React, { useEffect, useState } from "react";
import { months } from "../../../constants";
import CalendarMonth from "./CalendarMonth";

export default function Calendar({ user, accountingHistory, handleResultViewUpdate }) {
	const currentMonth = new Date().getMonth();
	const currentYear = new Date().getFullYear();
	const [year, setYear] = useState(new Date().getFullYear());
	const [monthStatus, setMonthStatus] = useState({}); // disabled, input, editable, final

	useEffect(() => {
		const currentMonthYear = currentYear * 12 + currentMonth;
		const monthStatus = {};
		for (let i = 0; i < 12; i++) {
			const m = `${months[i]}`;
			const my = `${m}${year}`;
			if (accountingHistory.hasOwnProperty(my)) {
				if (accountingHistory[my].finalized) monthStatus[m] = "final";
				else monthStatus[m] = "editable";
			} else if (!(year * 12 + i === currentMonthYear || year * 12 + i === currentMonthYear - 1)) monthStatus[m] = "disabled";
			else monthStatus[m] = "input";
		}
		setMonthStatus(monthStatus);
	}, [year]);

	const handleYearSet = (newYear) => {
		if (newYear < 2022) return;
		else setYear(newYear);
	};

	return (
		<div className="acc-calendar-cnt">
			<div className="acc-calendar-header">
				<span hidden={year === 2022} onClick={() => handleYearSet(year - 1)}>{` < `}</span>
				<h2>{year}</h2>
				<span onClick={() => handleYearSet(year + 1)}>{` > `}</span>
			</div>
			<div className="acc-calendar">
				{months.map((month) => (
					<CalendarMonth
						user={user}
						key={`${month}${year}`}
						month={month}
						status={monthStatus[month]}
						handleResultViewUpdate={() => {
							handleResultViewUpdate(`${month}${year}`);
						}}
						viewStudentSummary={() => {
							handleResultViewUpdate(`student-${month}${year}`);
						}}
					/>
				))}
			</div>
		</div>
	);
}
