import React from "react";

export default function DealUserSelect({ users = {}, value = null, selectUser }) {
	const sortedUsersList = Object.entries(users).sort((a, b) => a[1].localeCompare(b[1]));

	return (
		<select defaultValue={value} placeholder="Select a person" onChange={(e) => selectUser(e.target.value)}>
			<option value={""}>Select a person</option>
			{sortedUsersList.map((user) => (
				<option value={user[0]}>{user[1]}</option>
			))}
		</select>
	);
}
