import React, { Component } from "react";
import { UserContext } from "../../UserContext";

import getPreviousMonth from "../functions/getPreviousMonth";
import SiteInput from "./SiteInput";
import base from "../../../base";
import AddScreenshotOverlay from "./AddScreenshotOverlay";
import AddSiteOverlay from "./AddSiteOverlay";
import AddNoteOverlay from "./AddNoteOverlay";
import getDefaultDeal from "../functions/getDefaultDeal";
import getActiveDeals from "../functions/getActiveDeals";
import { removeNonDigits, removeNumbers } from "../../../helpers";
import SettlementInput from "./SettlementInput";

// props:
//  accountingHistory: {}
//  monthYear: "mmmYYYY"
//  finalizeMonthlyResult: f(monthYear)
//  dealStatus: {id: "default"/"active"/"inactive"},
//  dealData: {},

export default class MonthlyResultInput extends Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			sites: {},
			previousMonth: "",
			previousBankrolls: {},
			displaySiteOverlay: false,
			displayScreenshotOverlay: false,
			screenshotBeingAdded: { site: null, type: null },
			displayNoteOverlay: false,
			noteBeingAddedTo: null,
		};
	}

	defaultDeal = getDefaultDeal(this.props.dealStatus);
	activeDeals = getActiveDeals(this.props.dealStatus);

	componentDidMount() {
		const previousMonth = getPreviousMonth(this.props.accountingHistory, this.props.monthYear);
		let previousBankrolls = {};
		let newMonthStartingState = {};
		if (previousMonth) {
			const previousMonthSites = this.props.accountingHistory[previousMonth].sites;
			Object.keys(previousMonthSites).forEach((site) => (previousBankrolls[site] = previousMonthSites[site].endBankroll));

			// get a blank new month as the default starting state if there are previous monthly sites to go from
			Object.keys(previousMonthSites).forEach((site) => {
				if (previousMonthSites[site].deal === "settlement") return;
				newMonthStartingState[site] = {
					deal:
						previousMonthSites[site].deal && this.props.dealStatus[previousMonthSites[site].deal] !== "inactive"
							? previousMonthSites[site].deal
							: this.defaultDeal,
					currency: previousMonthSites[site].currency,
					endBankroll: 0,
					netTransactions: 0,
					notes: [],
					screenshots: {
						bankroll: [],
						transactions: [],
					},
				};
			});
		}

		this.sitesRef = base.syncState(`accountingHistory/${this.props.user}/${this.props.monthYear}/sites`, {
			context: this,
			state: "sites",
			defaultValue: newMonthStartingState,
		});

		this.setState({ previousMonth, previousBankrolls, loading: false });
	}

	componentWillUnmount() {
		base.removeBinding(this.sitesRef);
	}

	handleSiteUpdate = (value, site, field) => {
		const sites = this.state.sites;
		sites[site][field] = value;
		this.setState({ sites });
	};

	// Add Site functions
	handleAddSiteButton = () => {
		this.setState({ displaySiteOverlay: true }, () => {
			const input = document.getElementById("site-input");
			input.focus();
		});
	};
	handleSiteAdd = (name, currency, oneTimeSettlement = false) => {
		const sites = this.state.sites;
		sites[name] = {
			deal: !oneTimeSettlement ? this.defaultDeal : "settlement",
			currency: currency,
			endBankroll: 0,
			netTransactions: 0,
			notes: [],
			screenshots: {
				bankroll: [],
				transactions: [],
			},
		};
		this.setState({ sites, displaySiteOverlay: false });
	};
	handleSiteDelete = (name) => {
		if (!window.confirm("Do you want to remove this site from this and future month's accounting?\n")) return;
		// have to remove it from firebase instead of from state, for some reason
		base.remove(`accountingHistory/${this.props.user}/${this.props.monthYear}/sites/${name}`);
		window.alert(`${name} removed from accounting`);
	};

	// Screenshot functions
	openScreenshotOverlay = (site, type) => {
		this.setState({ displayScreenshotOverlay: true, screenshotBeingAdded: { site, type } }, () => {
			const input = document.getElementById("screenshot-input");
			input.focus();
		});
	};
	closeScreenshotOverlay = () => this.setState({ displayScreenshotOverlay: false, screenshotBeingAdded: { site: null, type: null } });
	handleScreenshotAdd = (link) => {
		const sites = this.state.sites;
		const site = this.state.screenshotBeingAdded.site;
		const type = this.state.screenshotBeingAdded.type;
		if (!sites[site].screenshots) sites[site].screenshots = { bankroll: [], transactions: [] };
		if (!sites[site].screenshots[type]) sites[site].screenshots[type] = [];
		sites[site].screenshots[type].push(link);
		this.setState({ sites, displayScreenshotOverlay: false, screenshotBeingAdded: { site: null, type: null } });
	};
	handleScreenshotRemove = (index, site, type) => {
		const sites = this.state.sites;
		sites[site].screenshots[type].splice(index, 1);
		this.setState({ sites });
	};

	// Note functions
	openNoteOverlay = (site) => {
		this.setState({ displayNoteOverlay: true, noteBeingAddedTo: site }, () => {
			const input = document.getElementById("note-input");
			input.focus();
		});
	};
	closeNoteOverlay = () => this.setState({ displayNoteOverlay: false, noteBeingAddedTo: null });
	handleNoteAdd = (note) => {
		const sites = this.state.sites;
		const site = this.state.noteBeingAddedTo;
		if (!sites[site].hasOwnProperty("notes")) sites[site].notes = [];
		sites[site].notes.push(note);
		this.setState({ sites, displayNoteOverlay: false, noteBeingAddedTo: null });
	};
	handleNoteRemove = (index, site) => {
		const sites = this.state.sites;
		sites[site].notes.splice(index, 1);
		this.setState({ sites });
	};

	finalizeResult = async () => {
		if (this.state.previousMonth && !this.props.accountingHistory[this.state.previousMonth].finalized) {
			alert("Please finalize previous month's numbers first");
			return;
		}
		if (window.confirm("Finalize results?")) {
			this.props.finalizeMonthlyResult();
			alert("Results finalized");
		}
	};

	render() {
		if (this.state.loading) return <></>;
		return (
			<div>
				<h2 className="month-input-header">
					{`Input Results for ${removeNumbers(this.props.monthYear)} ${removeNonDigits(this.props.monthYear)}`}
				</h2>
				<div className="monthly-result-input-ctn">
					{Object.keys(this.state.sites).map((site, i) => {
						if (this.state.sites[site].deal !== "settlement")
							return (
								<SiteInput
									key={site}
									index={i}
									siteName={site}
									siteInfo={this.state.sites[site]}
									previousBankroll={this.state.previousBankrolls[site]}
									handleSiteUpdate={this.handleSiteUpdate}
									openScreenshotOverlay={this.openScreenshotOverlay}
									closeScreenshotOverlay={this.closeScreenshotOverlay}
									handleScreenshotAdd={this.handleScreenshotAdd}
									handleScreenshotRemove={this.handleScreenshotRemove}
									openNoteOverlay={this.openNoteOverlay}
									handleNoteAdd={this.handleNoteAdd}
									handleNoteRemove={this.handleNoteRemove}
									deleteSite={this.handleSiteDelete}
									dealData={this.props.dealData}
									activeDeals={this.activeDeals}
									defaultDeal={this.defaultDeal}
								/>
							);
						return (
							<SettlementInput
								key={site}
								index={i}
								siteName={site}
								siteInfo={this.state.sites[site]}
								handleSiteUpdate={this.handleSiteUpdate}
								deleteSite={this.handleSiteDelete}
								openScreenshotOverlay={this.openScreenshotOverlay}
								handleScreenshotRemove={this.handleScreenshotRemove}
								openNoteOverlay={this.openNoteOverlay}
								handleNoteRemove={this.handleNoteRemove}
							/>
						);
					})}
					<div className="acc-month-extra-buttons">
						<button className="acc-add-site-button" onClick={() => this.handleAddSiteButton()} title="Add new site">
							+
						</button>
						<button className="finalize-results" onClick={() => this.finalizeResult()}>
							Finalize Results
						</button>
					</div>
					<AddScreenshotOverlay
						displayOverlay={this.state.displayScreenshotOverlay}
						addScreenshot={this.handleScreenshotAdd}
						closeScreenshotOverlay={this.closeScreenshotOverlay}
					/>
					<AddSiteOverlay
						displayOverlay={this.state.displaySiteOverlay}
						addSite={this.handleSiteAdd}
						closeSiteOverlay={() => this.setState({ displaySiteOverlay: false })}
					/>
					<AddNoteOverlay
						displayOverlay={this.state.displayNoteOverlay}
						addNote={this.handleNoteAdd}
						closeNoteOverlay={() => this.setState({ displayNoteOverlay: false })}
					/>
				</div>
			</div>
		);
	}
}
