import React, { useState } from "react";
import makeURL from "../../../helpers";

export default function AddScreenshotOverlay({ displayOverlay = false, addScreenshot, closeScreenshotOverlay }) {
	const [url, setUrl] = useState("");

	const handleButtonClick = (e) => {
		e.preventDefault();
		addScreenshot(makeURL(url));
		setUrl("");
	};

	//TODO: validate URL ie. no whitespace, etc

	return (
		<div className={`screenshot-overlay ${displayOverlay ? "" : "display-none"}`} onClick={() => closeScreenshotOverlay()}>
			<div className={`add-screenshot-cnt`} onClick={(e) => e.stopPropagation()}>
				<h4>Add Screenshot</h4>
				<form onSubmit={(e) => handleButtonClick(e)}>
					<span>URL:</span>
					<input id="screenshot-input" value={url} onChange={(e) => setUrl(e.target.value)} autoComplete="off" />
					<button type="submit">Save</button>
				</form>
			</div>
		</div>
	);
}
