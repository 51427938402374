import React from "react";
import onTouchScreen from "../../Mobile/onTouchScreen";

export default function PositionButton({ position, canAct, isActing, betColor, handleLeftClick, handleRightClick }) {
	let timer;
	const touchscreen = onTouchScreen();

	// const handleTouchStart = () => (timer = Date.now());
	// const handleTouchEnd = (e) => {
	// 	if (!iOS) return;
	// 	if (Date.now() - timer < 500) return;
	// 	else {
	// 		handleRightClick(e);
	// 	}
	// };

	const handleTouchStart = (e) => {
		timer = setTimeout(() => handleRightClick(e), 500);
	};
	const handleTouchEnd = () => clearTimeout(timer);

	const disableTouchContextMenu = (e) => e.preventDefault();

	return (
		<button
			className={`drd-pos-btn ${isActing ? "pressed" : ""} ${betColor}`}
			disabled={!canAct && !isActing}
			onTouchStart={(e) => handleTouchStart(e)}
			onTouchEnd={handleTouchEnd}
			onClick={handleLeftClick}
			onContextMenu={touchscreen ? (e) => disableTouchContextMenu(e) : (e) => handleRightClick(e)}>
			{position}
		</button>
	);
}
