import React from "react";

export default function DealShareholderInput({ id, name, dealType, updateShareholderBreakdown, removeShareholder }) {
	const backingInput = (
		<span>
			<span className="adc-percentage">
				Backing Share:
				<input
					className="adc-shareholder-input"
					type="numeric"
					defaultValue={0}
					onChange={(e) => updateShareholderBreakdown(e.target.value, id, "backing")}
				/>
				%
			</span>
		</span>
	);

	const actionInput = (
		<span>
			<span className="adc-percentage">
				Action Share:
				<input
					className="adc-shareholder-input"
					type="numeric"
					defaultValue={0}
					onChange={(e) => updateShareholderBreakdown(e.target.value, id, "action")}
				/>
				%
			</span>
		</span>
	);

	const bankrollInput = (
		<span className="adc-percentage">
			Bankroll Ownership:
			<input
				className="adc-shareholder-input"
				type="numeric"
				defaultValue={0}
				onChange={(e) => updateShareholderBreakdown(e.target.value, id, "bankroll")}
			/>
			%
		</span>
	);

	return (
		<div className="adc-shareholder-cnt">
			<span className="adc-sh-name">{name}</span>
			{dealType !== "action" ? backingInput : <></>}
			{dealType !== "backing" ? actionInput : <></>}
			{bankrollInput}
			<span className="adc-sh-remove" onClick={() => removeShareholder(id)}>
				x
			</span>
		</div>
	);
}
