// React stuff
import React, { useState } from "react";
// Range Functions
import exportRangeToPio from "../functions/exportRangeToPio";
import PioCopySuccess from "./PioCopySuccess";
// Material UI
import InputSharpIcon from "@material-ui/icons/InputSharp";
import Tooltip from "@material-ui/core/Tooltip";
// Constants and Helpers
import { actionNames } from "../../../constants";
import { lastElement, removePositionText } from "../../../helpers";
import { round } from "lodash";

export default function RangeTitleBar({ action, normRange, frequency, hideExport = false }) {
	const [copySuccess, setCopySuccess] = useState(false);

	const actionCode = removePositionText(lastElement(action.split(",")));
	const actionName = actionNames[actionCode] ? actionNames[actionCode] : "Raise";

	return (
		<div className={`range-chart-title-div`}>
			<h5 className="range-chart-title">{`${actionName} - ${round(frequency * 100, 1)}%`}</h5>
			<Tooltip className={`${hideExport ? "display-none" : ""}`} title="Export range to PIO" placement="right">
				<button className="pio-export-button" onClick={() => exportRangeToPio(normRange, setCopySuccess)}>
					<InputSharpIcon className="pio-export-icon" style={{ fontSize: "1.2em" }} />
				</button>
			</Tooltip>
			<PioCopySuccess copySuccess={copySuccess} setCopySuccess={setCopySuccess} />
		</div>
	);
}
