import React from "react";
import { UserContext } from "../../UserContext";
import Checkbox from "../../Shared/components/Checkbox";
import { allTrainingScenarios } from "../../../constants";
import Loading from "../../Loading";
import MultiTreeSelector from "../../Shared/components/MultiTreeSelector";
import { isObjEmpty } from "../../../helpers";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

class TrainingSetup extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			advancedScenariosOpen: false,
		};
	}

	getHeroPositionInputOptions = (seats) => {
		const positionInputOptions = seats.map((pos) => (
			<label key={pos}>
				<Checkbox
					key={pos + "-p"}
					name={pos}
					checked={this.props.selectedHeroPositions.includes(pos)}
					onChange={(e) => this.props.handleOptionClick(e, "heroPositions")}
				/>
				{pos}
			</label>
		));
		positionInputOptions.unshift(
			<label key="all">
				<Checkbox
					key="all-pc"
					name="all"
					checked={this.props.selectedHeroPositions.length === seats.length}
					onChange={(e) => this.props.handleOptionClick(e, "heroPositions")}
				/>
				All
			</label>
		);
		return positionInputOptions;
	};

	getOppPositionInputOptions = (seats) => {
		const positionInputOptions = seats.map((pos) => (
			<label key={pos}>
				<Checkbox
					key={pos + "-p"}
					name={pos}
					checked={this.props.selectedOppPositions.includes(pos)}
					onChange={(e) => this.props.handleOptionClick(e, "oppPositions")}
				/>
				{pos}
			</label>
		));
		positionInputOptions.unshift(
			<label key="all">
				<Checkbox
					key="all-pc"
					name="all"
					checked={this.props.selectedOppPositions.length === seats.length}
					onChange={(e) => this.props.handleOptionClick(e, "oppPositions")}
				/>
				All
			</label>
		);
		return positionInputOptions;
	};

	getScenarioInputOptions = () => {
		let scenarioInputOptions = [];
		let advancedOptions = [];
		let arrow = this.props.advancedScenariosOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;
		const basicScenarios = ["rfi", "frfi", "f3bet", "f4bet"];
		basicScenarios.forEach((scn) => {
			if (this.props.allAvailableScenarios.includes(scn)) {
				scenarioInputOptions.push(
					<label key={scn}>
						<Checkbox
							key={scn + "-s"}
							name={scn}
							checked={this.props.selectedScenarios.includes(scn)}
							onChange={(e) => this.props.handleOptionClick(e, "scenarios")}
						/>
						{allTrainingScenarios[scn]}
					</label>
				);
			}
		});
		scenarioInputOptions.push(
			<div key={"adv-options"} className="rt-setup-options">
				<br />
				<b className="arrow-drop-container" onClick={() => this.props.handleAdvancedScenariosOpen(!this.props.advancedScenariosOpen)}>
					{arrow} Advanced {arrow}
				</b>
				<br />
				<div className={`rt-setup-options ${this.props.advancedScenariosOpen ? "" : "display-none"}`}>{advancedOptions}</div>
			</div>
		);
		this.props.allAvailableScenarios.forEach((scn) => {
			if (!basicScenarios.includes(scn)) {
				advancedOptions.push(
					<label key={scn}>
						<Checkbox
							key={scn + "-s"}
							name={scn}
							checked={this.props.selectedScenarios.includes(scn)}
							onChange={(e) => this.props.handleOptionClick(e, "scenarios")}
						/>
						{allTrainingScenarios[scn]}
					</label>
				);
			}
		});
		scenarioInputOptions.unshift(
			<label key="all">
				<Checkbox
					key="all-sc"
					name="all"
					checked={this.props.selectedScenarios.length === this.props.allAvailableScenarios.length}
					onChange={(e) => this.props.handleOptionClick(e, "scenarios")}
				/>
				Select All
			</label>
		);
		return scenarioInputOptions;
	};

	getMixInputOptions = () => {
		const mixInputOptions = [
			<label key="mx-hf">
				<Checkbox
					key="mx-hf"
					name="high-freq"
					checked={this.props.mixOption === "high-freq"}
					onChange={(e) => this.props.handleOptionClick(e, "mix")}
				/>
				Easy - No Mixing
			</label>,
			<label key="mx-10p">
				<Checkbox
					key="mx-10p"
					name="10p"
					checked={this.props.mixOption === "10p"}
					onChange={(e) => this.props.handleOptionClick(e, "mix")}
				/>
				Medium - Nearest 10%
			</label>,
			<label key="mx-ex">
				<Checkbox
					key="mx-ex"
					name="exact"
					checked={this.props.mixOption === "exact"}
					onChange={(e) => this.props.handleOptionClick(e, "mix")}
				/>
				Hard - Exact Mixing
			</label>,
		];
		return mixInputOptions;
	};

	render() {
		let heroPositionInputOptions, oppPositionInputOptions, scenarioInputOptions, mixInputOptions, display;
		// Prevents crash on refresh
		if (this.props.loading) display = <Loading />;
		else if (this.props.seatNames) {
			heroPositionInputOptions = this.getHeroPositionInputOptions(this.props.seatNames);
			oppPositionInputOptions = this.getOppPositionInputOptions(this.props.seatNames);
			scenarioInputOptions = this.getScenarioInputOptions();
			mixInputOptions = this.getMixInputOptions();
			display = (
				<div hidden={isObjEmpty(this.props.treesInfo)}>
					<div>
						<h2 className="training-form-header">Select training focus</h2>
						<div className="training-form-options-container">
							<div className="rt-setup-positions rt-setup-group">
								<div className="rt-setup-options">
									<h4>Hero Position</h4>
									{heroPositionInputOptions}
								</div>
								<div className="rt-setup-options">
									<h4>Opponent Position</h4>
									{oppPositionInputOptions}
								</div>
							</div>
							<div className="rt-setup-options rt-setup-group">
								<h4>Scenario</h4>
								{scenarioInputOptions}
							</div>
							<div className="rt-setup-options rt-setup-group">
								<h4>Mix Strategy Training</h4>
								{mixInputOptions}
							</div>
						</div>
						<div className="rt-setup-btn-container">
							<button className="rt-setup-btn" onClick={this.props.clearAllSelections}>
								Clear All
							</button>
							<input type="submit" value="Launch" className="rt-setup-btn" />
						</div>
					</div>
				</div>
			);
		} else {
			display = <></>;
		}
		return (
			<form className="training-form" onSubmit={this.props.launchTraining}>
				<h2 className="training-form-header">Select one or more trees</h2>
				<MultiTreeSelector loadTrees={this.props.handleTreeSelect} gameFeaturesMatch={true} />
				{display}
			</form>
		);
	}
}

export default TrainingSetup;
