import sortActions from "./sortActions";
import { getMaxValueKey } from "../../../helpers";

const getCorrectAction = (actionFrequencies, rngRoll = null) => {
	let correctAction;
	if (!rngRoll) correctAction = getMaxValueKey(actionFrequencies);
	else {
		let totalFrequency = 0;
		const actions = sortActions(Object.keys(actionFrequencies));
		for (let i = 0; i < actions.length; i++) {
			totalFrequency += actionFrequencies[actions[i]];
			if (rngRoll <= totalFrequency) {
				correctAction = actions[i];
				break;
			}
			correctAction = actions[i];
		}
	}
	return correctAction;
};

export default getCorrectAction;
