import React from "react";
// Mui
import { makeStyles, TableCell, TableRow, withStyles } from "@material-ui/core";
// Data functions
import getAggressionFactor from "../../functions/getAggressionFactor";
import getMasteryFactor from "../../functions/getMasteryFactor";
// Shared functions and helpers
import addScore from "../../../Shared/functions/addScore";
import { getAccuracy, getColorFromPercentage } from "../../../../helpers";

export default function SpotDrilldown({ pos, opps, data, repScores, scenarioRepData, handleSpotSelect, selectedSpot }) {
	const StyledTableRow = withStyles(() => ({
		root: {
			"&:nth-child(even)": {
				backgroundColor: "rgba(0,0,0,.2)",
			},
			"&:nth-child(odd)": {
				backgroundColor: "rgba(0,0,0,0.4)",
			},
			"&:hover": {
				backgroundColor: "rgba(0,0,0,0.6)",
			},
		},
	}))(TableRow);
	const StyledTableCell = withStyles(() => ({
		body: {
			color: "white",
			border: "none",
			"& >button": {
				padding: -1,
			},
		},
	}))(TableCell);
	const useStyles = makeStyles({
		selected: {
			backgroundColor: "rgba(0,0,0,0.7) !important",
			boxShadow: "inset 0px 0px 0px 2px white !important",
		},
	});
	const classes = useStyles();

	const accuracy = getAccuracy(data.reduce((acc, val) => addScore(acc, repScores[val]), "0/0"));
	const aggressionFactor = getAggressionFactor(data, repScores, scenarioRepData);
	const masteryFactor = getMasteryFactor(data, repScores);

	return (
		<StyledTableRow key={opps} className={selectedSpot === `${pos}-${opps}` ? classes.selected : ""} onClick={() => handleSpotSelect(pos, opps, data)}>
			<StyledTableCell component="th" scope="row">
				{opps.toUpperCase()}
			</StyledTableCell>
			<StyledTableCell>{data.length}</StyledTableCell>
			<StyledTableCell align="right" className="data-stat" style={{ color: getColorFromPercentage(accuracy / 100) }}>
				{`${accuracy}%`}
			</StyledTableCell>
			<StyledTableCell align="right" className="data-stat" style={{ color: getColorFromPercentage(aggressionFactor, "aggression") }}>
				{aggressionFactor}
			</StyledTableCell>
			<StyledTableCell align="right" className="data-stat" style={{ color: getColorFromPercentage(masteryFactor / 100, "mastery") }}>{`${masteryFactor}%`}</StyledTableCell>
		</StyledTableRow>
	);
}
