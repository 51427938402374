import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../UserContext";
import Loading from "../../Loading";

import SettingsTooltip from "./SettingsTooltip";

import Switch from "@material-ui/core/Switch";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { MenuItem, Select } from "@material-ui/core";

export default function Settings() {
	const context = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setLoading(false);
	}, []);

	if (loading) return <Loading />;
	else
		return (
			<div>
				<h1 className="page-heading">Settings</h1>
				{/* DRD */}
				<h3 className="settings-heading">Range Display</h3>
				<List style={{ maxWidth: "600px", padding: "2px" }} component={Paper}>
					<ListItem>
						Large Range Displays
						<Switch
							checked={context.settings?.drdLargeDisplays ? context.settings.drdLargeDisplays : false}
							onChange={() => context.handleSettingsUpdate("drdLargeDisplays", !context.settings.drdLargeDisplays)}
						/>
						<SettingsTooltip content="Increases the size of the Range Display popup" />
					</ListItem>
				</List>
				{/* DRT */}
				<h3 className="settings-heading">Range Trainer</h3>
				<List style={{ maxWidth: "600px", padding: "2px" }} component={Paper}>
					<ListItem>
						RNG Roll Low to High
						<Switch
							checked={context.settings?.rtRngLowToHigh ? context.settings.rtRngLowToHigh : false}
							onChange={() => context.handleSettingsUpdate("rtRngLowToHigh", !context.settings.rtRngLowToHigh)}
						/>
						<SettingsTooltip content="When toggled on, lower RNG rolls correspond to the more aggressive action" />
					</ListItem>
					<ListItem>
						Fast Deal
						<Switch
							checked={context.settings?.rtFastDeal ? context.settings.rtFastDeal : false}
							onChange={() => context.handleSettingsUpdate("rtFastDeal", !context.settings.rtFastDeal)}
						/>
						<SettingsTooltip content="Skip animations of previous players acting - jump to the spot being tested" />
					</ListItem>
					<ListItem>
						Tight Hand Selection
						<Switch
							checked={context.settings?.rtTightHandSelect ? context.settings.rtTightHandSelect : false}
							onChange={() => context.handleSettingsUpdate("rtTightHandSelect", !context.settings.rtTightHandSelect)}
						/>
						<SettingsTooltip content="Select from a narrower pool of hands to be trained on - within one pip of the range border or a mix option" />
					</ListItem>
					<ListItem>
						Show Spot Frequency:
						<Select
							className="settings-select"
							value={context.settings?.rtShowPrevFreq ? context.settings.rtShowPrevFreq : "<100"}
							onChange={(e) => context.handleSettingsUpdate("rtShowPrevFreq", e.target.value)}>
							<MenuItem value={"never"}>Never</MenuItem>
							<MenuItem value={"<100"}>Only Mixed Spots</MenuItem>
							<MenuItem value={"always"}>Always</MenuItem>
						</Select>
						<SettingsTooltip content="Shows the frequency of the hand/spot you're training on your HUD" />
					</ListItem>
					<ListItem>
						Proportional Hand Dealing
						<Switch
							checked={context.settings?.rtProportionalDeal ? context.settings.rtProportionalDeal : false}
							onChange={() => context.handleSettingsUpdate("rtProportionalDeal", !context.settings.rtProportionalDeal)}
						/>
						<SettingsTooltip
							content="Receive training hands dealt to you at the proportion they appear in the range. 
									So a spot you would have only arrived to 20% of the time will appear 5x less often. 
									<5% frequency hands are removed from the pool entirely."
						/>
					</ListItem>
					<ListItem>
						Common Scenarios Occur More Frequently
						<Switch
							checked={context.settings?.rtProportionalScenarios ? context.settings.rtProportionalScenarios : false}
							onChange={() => context.handleSettingsUpdate("rtProportionalScenarios", !context.settings.rtProportionalScenarios)}
						/>
						<SettingsTooltip
							content="When training across multiple scenarios, more common ones will appear more often. 
									For instance, RFI will appear more often than Facing 3bet, which will appear more often than Facing 4bet"
						/>
					</ListItem>
				</List>
				<h3 className="settings-heading">Other</h3>

				<List style={{ maxWidth: "600px", padding: "2px" }} component={Paper}>
					<ListItem>
						Multi-Tree Select
						<Switch
							checked={context.settings?.multiTreeSelect ? context.settings.multiTreeSelect : false}
							onChange={() => context.handleSettingsUpdate("multiTreeSelect", !context.settings.multiTreeSelect)}
						/>
						<SettingsTooltip content="Allows simultaneously selecting multiple trees in Range Display and Range Trainer" />
					</ListItem>
				</List>
			</div>
		);
}
