import React, { useContext, useEffect, useState } from "react";
import base from "../../../base";
import UserSelector from "../../TrainingData/components/UserSelector";
import { UserContext } from "../../UserContext";
import Calendar from "./Calendar";
import DealCreator from "./DealCreator";
import DealsOverview from "./DealsOverview";
import MonthlyResultInput from "./MonthlyResultInput";
import MonthlyResultSummary from "./MonthlyResultSummary";
import userIsAdmin from "../../Shared/functions/userIsAdmin";
import getDefaultDeal from "../functions/getDefaultDeal";
import StudentSummary from "./StudentSummary";

// for free exchange rate account, not worried about it - should I be?
const apiKey = "49d129faf75741968910f0fd7b3fc75e";

// https://openexchangerates.org/api/latest.json?app_id="49d129faf75741968910f0fd7b3fc75e"&base="USD"

// probably should have used context for a lot of this

export default function Accounting({}) {
	const context = useContext(UserContext);
	const [accountingHistory, setAccountingHistory] = useState({});
	const [loading, setLoading] = useState(true);
	const [resultView, setResultView] = useState(null);
	const [menuView, setMenuView] = useState("calendar");
	const [user, setUser] = useState(context.uid);
	const [admin, setAdmin] = useState(false);

	const [dealStatus, setDealStatus] = useState({});
	const [dealData, setDealData] = useState({});
	const [accountingUsers, setAccountingUsers] = useState({});

	useEffect(() => {
		getAccountingData();
		getDealStatus();
	}, [user]);

	const getAccountingData = async () => {
		setLoading(true);
		const accountingHistory = await base.fetch(`accountingHistory/${user}`, { context: this });
		const accountingUsers = await base.fetch(`accountingUsers`, { context: this });
		const admin = await userIsAdmin(context.uid);
		setAdmin(admin);
		setAccountingUsers(accountingUsers);
		setAccountingHistory(accountingHistory);
		setLoading(false);
	};

	const getDealStatus = async () => {
		const deals = await base.fetch(`accountingDeals/users/${user}/deals`, { context: this });
		const dealPromises = [];
		const dealData = {};
		// zipper-ing the dealIDs w/in the promise all
		Object.keys(deals).forEach((dealid) => {
			dealPromises.push(dealid);
			dealPromises.push(base.fetch(`accountingDeals/deals/${dealid}`, { context: this }));
		});
		const dealRes = await Promise.all(dealPromises);
		for (let i = 0; i < dealRes.length; i = i + 2) dealData[dealRes[i]] = dealRes[i + 1];
		setDealStatus(deals);
		setDealData(dealData);
	};

	const handleResultViewUpdate = (newView) => {
		setResultView(newView);
		if (!newView) {
			setMenuView("calendar");
			getAccountingData();
			getDealStatus();
		} else setMenuView("");
	};

	const finalizeMonthlyResult = async (monthYear) => {
		// get most up to date accounting history before finalizing
		const newAccountingHistory = await base.fetch(`accountingHistory/${user}`, { context: this });
		if (!newAccountingHistory[monthYear]) {
			alert("There seems to be an issue - have you fully input your results? If so, screenshot & send to Jake.");
			return;
		}
		const currencies = ["USD", "CAD"];
		Object.keys(newAccountingHistory[monthYear].sites).forEach((site) => {
			if (!newAccountingHistory[monthYear].sites[site].hasOwnProperty("deal")) {
				newAccountingHistory[monthYear].sites[site].deal = getDefaultDeal(dealStatus);
				base.post(`accountingHistory/${user}/${monthYear}/sites/${site}/deal`, { data: getDefaultDeal(dealStatus) });
			}
			const cur = newAccountingHistory[monthYear].sites[site].currency;
			if (!currencies.includes(cur)) currencies.push(cur);
		});
		const res = await fetch(`https://openexchangerates.org/api/latest.json?app_id=${apiKey}&base="USD"`);
		const rates = await res.json();
		const currencyRates = {};
		currencies.forEach((cur) => (currencyRates[cur] = rates.rates[cur]));

		console.log(newAccountingHistory);

		// TODO: Don't replace rates if they already exist ie. in the event that you need to do some unfinalizing then refinalizing it keeps original r8s
		if (!newAccountingHistory[monthYear].hasOwnProperty("rates")) {
			base.post(`accountingHistory/${user}/${monthYear}/rates`, { data: currencyRates });
			newAccountingHistory[monthYear].rates = currencyRates;
		}

		base.post(`accountingHistory/${user}/${monthYear}/calcDate`, { data: rates.timestamp });
		newAccountingHistory[monthYear].calcDate = rates.timestamp;

		base.post(`accountingHistory/${user}/${monthYear}/finalized`, { data: true });
		newAccountingHistory[monthYear].finalized = true;

		setAccountingHistory(newAccountingHistory);
	};

	const unfinalizeMonth = async () => {
		if (!confirm("Do you wish to unfinalize this month's results?")) return;
		await base.post(`accountingHistory/${user}/${resultView}/finalized`, { data: false });
		setLoading(true);
		getAccountingData();
	};

	let display, buttons;
	if (loading) display = <></>;
	else if (menuView === "dealCreate") display = <DealCreator returnToCalendar={() => handleResultViewUpdate(null)} />;
	else if (menuView === "dealOverview")
		display = (
			<DealsOverview
				user={user}
				dealStatus={dealStatus}
				dealData={dealData}
				accountingUsers={accountingUsers}
				getDealStatus={getDealStatus}
			/>
		);
	else if (menuView === "calendar")
		display = <Calendar user={user} accountingHistory={accountingHistory} handleResultViewUpdate={handleResultViewUpdate} />;
	else if (resultView.startsWith("student"))
		display = (
			<StudentSummary user={user} monthYear={resultView.replace("student-", "")} dealData={dealData} accountingUsers={accountingUsers} />
		);
	else if (!accountingHistory[resultView]?.finalized)
		display = (
			<MonthlyResultInput
				user={user}
				accountingHistory={accountingHistory}
				monthYear={resultView}
				finalizeMonthlyResult={() => finalizeMonthlyResult(resultView)}
				dealStatus={dealStatus}
				dealData={dealData}
			/>
		);
	else
		display = (
			<MonthlyResultSummary
				user={user}
				accountingHistory={accountingHistory}
				monthYear={resultView}
				dealStatus={dealStatus}
				dealData={dealData}
				accountingUsers={accountingUsers}
			/>
		);

	buttons = !resultView ? (
		<div className="accounting-cnt">
			<span className={`accounting-view-btn ${menuView === "calendar" ? "active" : ""}`} onClick={() => setMenuView("calendar")}>
				Calendar
			</span>
			<span className={`accounting-view-btn ${menuView === "dealOverview" ? "active" : ""}`} onClick={() => setMenuView("dealOverview")}>
				Deals Overview
			</span>
			<span
				disabled={!admin}
				hidden={!admin}
				className={`accounting-view-btn ${menuView === "dealCreate" ? "active" : ""}`}
				onClick={() => setMenuView("dealCreate")}>
				Create Deal
			</span>
		</div>
	) : (
		<div className="accounting-cnt">
			<div>
				<span className={`accounting-view-btn`} onClick={() => handleResultViewUpdate(null)}>
					Back
				</span>
			</div>
		</div>
	);

	return (
		<div>
			<h1 className="page-heading">Accounting</h1>
			{buttons}
			<div className="acc-user-select-cnt" hidden={!admin}>
				<UserSelector selectUser={setUser} />
				<button
					className={`unfinalize-result-btn ${resultView && accountingHistory[resultView]?.finalized ? "" : "display-none"}`}
					onClick={() => unfinalizeMonth()}>
					Unfinalize Month
				</button>
			</div>
			<div>{display}</div>
		</div>
	);
}
