import { betNames } from "../../constants";
import { getPositionFromAction } from "../../helpers";

export default function getActionDescriptionWithCall(actions) {
	let actionDescription = "";
	let raiseLevel = 0;
	let isASqueeze = false;
	actions.forEach((action) => {
		let actionText;
		const pos = getPositionFromAction(action);
		const actionKey = action.replace(pos, "");
		if (actionDescription !== "") actionDescription += ", ";
		if (actionKey === "3") actionText = "All-in";
		else if (actionKey === "1") {
			actionText = "Call";
			isASqueeze = true;
		} else if (isASqueeze) {
			actionText = "Squeeze";
			raiseLevel += 1;
			isASqueeze = false;
		} else {
			actionText = betNames[raiseLevel];
			raiseLevel += 1;
		}
		actionDescription += pos.toUpperCase() + " " + actionText;
	});

	return actionDescription;
}
