import React from "react";
import roundFreq from "../functions/roundFreq";
import { round } from "lodash";
import PLODisplayHand from "./PLODisplayHand";

export default function PLORangeTile({ hand, index, handData, setSearchQuery }) {
	return (
		<div className="plo-range-tile" key={`${hand}${index}`}>
			<div className="plo-tile-item">{<PLODisplayHand hand={hand} setSearchQuery={setSearchQuery} />}</div>
			{handData.map((action, i) => {
				return (
					<div key={i} className="plo-tile-cell" style={{ background: `rgba(120, 200, 255, ${action?.f ? action.f : "0"})` }}>
						<div className="plo-tile-item">{`${round(action?.ev, 0)}`}</div>
						<div className="plo-tile-item">{`${roundFreq(action?.f ? action.f : "0")}`}</div>
					</div>
				);
			})}
		</div>
	);
}
