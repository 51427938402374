import React from "react";
import getMiniHandVisuals from "../../Shared/functions/getMiniHandVisuals";
import Paper from "@material-ui/core/Paper";
import { actionNames } from "../../../constants";
import { getAccuracy, getColorFromPercentage } from "../../../helpers";

export default function RepSummary({ info }) {
	let hand, timestamp, actionSelected, score;
	({ hand, timestamp, actionSelected, score } = info);
	const correctAction = info.correctAction ? info.correctAction : null;
	const rngRoll = info.rngRoll ? info.rngRoll : null;

	// Hand
	let handVisualComponents;
	handVisualComponents = getMiniHandVisuals(hand);

	// Timestamp
	timestamp = timestamp.substring(0, timestamp.lastIndexOf(" "));

	// Action Selected
	const actionName = actionNames[actionSelected] ? actionNames[actionSelected] : "Raise";

	// Correct Action
	let correctActionLabel;
	if (correctAction) {
		const correctActionName = actionNames[correctAction] ? actionNames[correctAction] : "Raise";
		correctActionLabel = (
			<div className="rep-summary-action">
				Correct Action: <b>{correctActionName}</b>
			</div>
		);
	}

	// RNG Roll
	let rngRollLabel;
	if (rngRoll) rngRollLabel = <div className="rep-summary-action">RNG: {rngRoll}</div>;

	// Score
	const scoreStyle = {
		fontWeight: "bold",
		fontSize: "0.9rem",
		textShadow: "1px 1px #222",
		color: getColorFromPercentage(getAccuracy(score) / 100),
	};

	return (
		<Paper className="rep-summary">
			{handVisualComponents}
			<div className="rep-summary-labels">
				<div className="rep-summary-timestamp">{timestamp}</div>
				<div className="rep-summary-action">
					Action Selected: <b>{actionName}</b>
				</div>
				{correctActionLabel}
				{rngRollLabel}
			</div>
			<div className="rep-summary-score" style={scoreStyle}>
				{score}
			</div>
		</Paper>
	);
}

// Properties that every repSummary will have: hand (visually), timestamp, action selected, score
// Properties that some will have: mixOption, correctAction

