import React, { useContext, useEffect, useState } from "react";
import base from "../../../base";
import { UserContext } from "../../UserContext";
import userIsAdmin from "../../Shared/functions/userIsAdmin";

export default function UserSelector({ selectUser }) {
	const userContext = useContext(UserContext);
	const [users, setUsers] = useState([]);
	const [show, setShow] = useState(false);

	useEffect(() => {
		getUsers();
	}, []);
	async function getUsers() {
		const isAdmin = await userIsAdmin(userContext.uid);
		setShow(isAdmin);
		if (!isAdmin) return;
		await base.fetch(`userList`, { context: this }).then((data) => setUsers(Object.keys(data).map((userID) => [userID, data[userID]])));
	}

	const userSelectOptions = users.map((user, i) => (
		<option value={user[0]} key={i}>
			{user[1]}
		</option>
	));

	if (show) {
		return (
			<div>
				<span className="select-tree-label">Select User:</span>
				<select
					id="user-selector"
					className="drd-tree-select"
					placeholder="Select User"
					defaultValue={""}
					onChange={(e) => selectUser(e.target.value)}>
					<option hidden disabled value="">
						Select User
					</option>
					{userSelectOptions}
				</select>
			</div>
		);
	} else return null;
}
