import React from "react";
// Mui
import { makeStyles, TableCell, TableRow, withStyles } from "@material-ui/core";
// Data functions
import getAggressionFactor from "../../functions/getAggressionFactor";
import getMasteryFactor from "../../functions/getMasteryFactor";
// Shared functions and helpers
import addScore from "../../../Shared/functions/addScore";
import { getAccuracy, getColorFromPercentage } from "../../../../helpers";

export default function RFIPositionRow({ pos, positionRepIDs, repScores, scenarioRepData, handleSpotSelect, selectedSpot }) {
	const StyledTableCell = withStyles(() => ({
		head: {
			padding: "10px",
			backgroundColor: "black",
			color: "white",
		},
		body: {
			color: "white",
			border: "none",
			"& >button": {
				padding: -1,
			},
			"& >button:hover": {
				filter: "brightness(80%)",
			},
		},
	}))(TableCell);
	const StyledTableRow = withStyles(() => ({
		root: {
			"&:nth-child(odd)": {
				backgroundColor: "rgba(0,0,0,0.4)",
			},
			"&:nth-child(even)": {
				backgroundColor: "rgba(0,0,0,0.5)",
			},
			"&:hover": {
				backgroundColor: "rgba(0,0,0,0.7)",
			},
		},
	}))(TableRow);
	const useStyles = makeStyles({
		stat: {
			fontWeight: "bold",
			fontSize: "0.9rem",
			textShadow: "1px 1px #222",
		},
		total: {
			borderTop: "2px solid black",
		},
		selected: {
			backgroundColor: "rgba(0,0,0,0.7) !important",
			boxShadow: "inset 0px 0px 0px 2px white !important",
		},
	});
	const classes = useStyles();

	const reps = positionRepIDs.length;
	const accuracy = getAccuracy(positionRepIDs.reduce((acc, id) => addScore(acc, repScores[id]), "0/0"));
	const aggressionFactor = getAggressionFactor(positionRepIDs, repScores, scenarioRepData);
	const masteryFactor = pos === "TOTAL" ? getMasteryFactor(positionRepIDs, repScores, 1000) : getMasteryFactor(positionRepIDs, repScores);
	let classNames = "";
	if (selectedSpot === `${pos}-`) classNames = classNames + classes.selected + " ";
	if (pos === "TOTAL") classNames = classNames + classes.total + " ";

	return (
		<React.Fragment>
			<StyledTableRow className={classNames} onClick={() => handleSpotSelect(pos, "", positionRepIDs)}>
				<StyledTableCell component="th" scope="row">
					{pos.toUpperCase()}
				</StyledTableCell>
				<StyledTableCell align="right">{reps}</StyledTableCell>
				<StyledTableCell align="right" className={classes.stat} style={{ color: getColorFromPercentage(accuracy / 100) }}>
					{accuracy}
				</StyledTableCell>
				<StyledTableCell align="right" className={classes.stat} style={{ color: getColorFromPercentage(aggressionFactor, "aggression") }}>
					{aggressionFactor}
				</StyledTableCell>
				<StyledTableCell align="right" className={classes.stat} style={{ color: getColorFromPercentage(masteryFactor / 100, "mastery") }}>{`${masteryFactor}%`}</StyledTableCell>
			</StyledTableRow>
		</React.Fragment>
	);
}
