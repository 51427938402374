import React from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import userIsBasic from "./Shared/functions/userIsBasic";
import { UserContext } from "./UserContext";

class SideBar extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			basic: true,
		};
	}

	async componentDidMount() {
		const basic = await userIsBasic(this.context.uid);
		this.setState({ basic });
	}

	toggleMenu = () => {
		const sidebar = document.getElementById("sidebar");
		if (sidebar.className.trim() === "sidebar") {
			sidebar.className += " show";
			// Listen for a click away from menu to close it
			window.addEventListener("mouseup", this.clickListener);
		} else {
			sidebar.className = "sidebar";
		}
	};

	closeMenu = () => {
		const sidebar = document.getElementById("sidebar");
		sidebar.className = "sidebar";
		window.removeEventListener("mouseup", this.clickListener);
	};

	clickListener = (e) => {
		if (!document.getElementById("sidebar").contains(e.target)) this.closeMenu();
	};

	render() {
		const path = window.location.pathname;
		return (
			<div className="sidebar" id="sidebar">
				<button className="sidebar-icon" id="sidebar" onClick={this.toggleMenu}>
					<MenuIcon />
				</button>
				<Link to="/" className="sidebar-link">
					<button className={`sidebar-button ${path === "/" ? "active" : ""}`} onClick={this.closeMenu}>
						Home
					</button>
				</Link>
				<Link to="/drd" className="sidebar-link">
					<button className={`sidebar-button ${path === "/drd" ? "active" : ""}`} onClick={this.closeMenu}>
						Range Display
					</button>
				</Link>
				<Link to="/trainer" className="sidebar-link">
					<button className={`sidebar-button ${path === "/trainer" ? "active" : ""}`} onClick={this.closeMenu}>
						Range Trainer
					</button>
				</Link>
				<Link to="/data" className={`sidebar-link`}>
					<button className={`sidebar-button ${path === "/data" ? "active" : ""}`} onClick={this.closeMenu}>
						Training Data
					</button>
				</Link>
			</div>
		);
	}
}

export default SideBar;
