import React from "react";

export class Tooltip extends React.Component {
	render() {
		let imgElement;
		if (this.props.img) imgElement = <img src={this.props.img} alt="Didn't work" width={this.props.width} />;
		return (
			<div className="tooltip info-icon">
				ℹ️
				<span className="tooltip-text" width={this.props.width}>
					{this.props.text}
					{imgElement}
				</span>
			</div>
		);
	}
}
