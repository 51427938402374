export default function formatHoldemRangeData(rangeData) {
	let array = rangeData.split("\n");
	array = Array.from({ length: array.length / 2 }, (_, i) => array[2 * i] + ";" + array[2 * i + 1].split(";")[0]);

	let formattedData = array.reduce((acc, val) => {
		let [key, value] = val.split(";");
		if (value === "0.0") return { ...acc };
		return { ...acc, [key.trim()]: value };
	}, {});

	//To make sure that even if there is a spot where you'd never take the action there is still an object representing it in the DB
	if (Object.keys(formattedData).length === 0) formattedData["AA"] = "0.0";

	return formattedData;
}
