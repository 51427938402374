import calculateProfit from "./calculateProfit";
import fx from "./money";

export default function getProfitAndNetTransactionsByDeal(sites, previousBankrolls, currencyToDisplay, rates = { USD: "1" }) {
	fx.base = "USD";
	fx.rates = rates;

	let totalProfit = 0.0;
	const profitByDeal = {};
	const netTransactionsByDeal = {};

	Object.keys(sites).forEach((site) => {
		const deal = sites[site].deal;
		const siteTransactions = sites[site].netTransactions ? sites[site].netTransactions : 0;
		const startAmount = previousBankrolls[site] ? previousBankrolls[site] : sites[site].initialBankroll;
		const cur = sites[site].currency;
		const siteProfit = calculateProfit(startAmount, siteTransactions, sites[site].endBankroll);
		totalProfit += fx(siteProfit).from(cur).to(currencyToDisplay);
		netTransactionsByDeal.hasOwnProperty(deal)
			? (netTransactionsByDeal[deal] += fx(parseFloat(siteTransactions)).from(cur).to(currencyToDisplay))
			: (netTransactionsByDeal[deal] = fx(parseFloat(siteTransactions)).from(cur).to(currencyToDisplay));
		profitByDeal.hasOwnProperty(deal)
			? (profitByDeal[deal] += fx(siteProfit).from(cur).to(currencyToDisplay))
			: (profitByDeal[deal] = fx(siteProfit).from(cur).to(currencyToDisplay));
	});

	return { totalProfit, profitByDeal, netTransactionsByDeal };
}
