import React from "react";

class Dashboard extends React.Component {
	render() {
		return (
			<div>
				<h1 key="header" className="page-heading homepage-header">
					Welcome to Preflop Mastery
				</h1>
			</div>
		);
	}
}

export default Dashboard;
