// React Stuff
import React from "react";
import { UserContext } from "../../UserContext";
// Data Specific Functions & Components
import fetchUserRepHistory from "../functions/fetchUserRepHistory";
import DataOverview from "./DataOverview";
import ScenarioBreakdown from "./ScenarioBreakdown";
import UserSelector from "./UserSelector";
// Shared Functions & Components
import Loading from "../../Loading";
import MultiTreeSelector from "../../Shared/components/MultiTreeSelector";
import { isEmpty } from "../../../helpers";
import userIsBasic from "../../Shared/functions/userIsBasic";
// Helpers & Constants

class TrainingData extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			userRepHistory: null,
			scenario: null,
			loading: true,
			previousCurrentTree: null,
			userBeingViewed: null,
			userBeingViewedTrees: null,
			basic: true,
		};
	}

	setScenario = (scenario) => this.setState({ scenario });
	selectUser = async (uid) => {
		// Grabbing the admin's current tree before they start browsing other users, to jump back to when done exploring other users
		const previousCurrentTree = this.state.previousCurrentTree ? this.state.previousCurrentTree : this.context.currentTrees;
		// Setting the tree shown in the data to be the first one in the user's list
		this.context.handleTreesChange([]);
		this.setState({ userBeingViewed: uid, previousCurrentTree }, () => this.loadTree());
	};
	backToDataOverview = () => this.setState({ scenario: null });

	async componentDidMount() {
		const basic = await userIsBasic(this.context.uid);
		this.setState({ basic });
		if (this.context.currentTrees.length > 1) this.context.handleTreesChange([this.context.currentTrees[0]]);
		this.loadTree();
	}

	componentWillUnmount() {
		if (this.state.previousCurrentTree) this.context.handleTreesChange(this.state.previousCurrentTree);
	}

	loadTree = async () => {
		const user = this.state.userBeingViewed ? this.state.userBeingViewed : this.context.uid;
		let userRepHistory = null;
		if (!isEmpty(this.context.currentTrees)) {
			userRepHistory = await fetchUserRepHistory(user, this.context.currentTrees[0].id);
		}
		this.setState({ userRepHistory, loading: false });
	};

	// Controller to conditionally render the current view
	render() {
		let display, selector;
		// if (this.state.basic) return <div>(403) - Access Denied</div>;
		if (this.state.loading) display = <Loading />;
		else {
			if (!this.state.scenario) {
				selector = (
					<div>
						<UserSelector selectUser={this.selectUser} />
						<div className="data-selector-container">
							<span className="select-tree-label">Select Tree:</span>
							{/* Passing in the userBeingViewed here even though it's unused to force the TreeSelector to properly rerender & jump to the top (it won't 'notice' a change in customOptions) */}
							<MultiTreeSelector
								style={{ float: "right" }}
								loadTrees={this.loadTree}
								userBeingViewed={this.state.userBeingViewed}
								customOptions={this.state.userBeingViewedTrees}
								selectionLimit={1}
							/>
						</div>
					</div>
				);
				if (this.context.currentTrees.length && this.state.userRepHistory)
					display = (
						<div>
							<DataOverview userRepHistory={this.state.userRepHistory} setScenario={this.setScenario} />
						</div>
					);
			} else {
				display = (
					<ScenarioBreakdown
						scenario={this.state.scenario}
						repScores={this.state.userRepHistory[this.state.scenario]}
						backToDataOverview={this.backToDataOverview}
					/>
				);
				selector = <></>;
			}
		}
		return (
			<div>
				<h1 key="header" className="page-heading">
					Training Data
				</h1>
				{selector}
				{display}
			</div>
		);
	}
}

export default TrainingData;
