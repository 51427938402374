import React from "react";

export default function TreeNameAndRakeFAQ() {
	return (
		<div>
			<div className="tree-rake-faq-cnt">
				<h3>
					<u>Tree Naming Convention:</u>
				</h3>
				<p>
					The names of each public Monker tree aim to give the most information possible about the parameters used when constructing the
					tree.
					<br />
					This is the order that information is included in:
				</p>
				<span>
					<b>[Game Type] [# of Players] [Stack Depth] [Open Size] [Cold Calls] [Other Details] [Rake Structure]</b>
				</span>
				<p>The default assumtions are NLHE, 100bb, and No Cold Calling</p>
			</div>
			<div className="tree-rake-faq-cnt">
				<h3>
					<u>Rake Structure:</u>
				</h3>
				<p>The rake structure of many games is similar enough to be bucketed into categories: Low, Medium, High, and Very High Rake.</p>
				Roughly, these are the site/stake combos that correspond to each rake category:
				<ul className="rake-faq-list">
					<li className="rake-faq-list-head">
						<b>Very High (2.5bb cap):</b>
						<ul className="rake-faq-sublist">
							<li>Bodog 200</li>
							<li>GG 200</li>
						</ul>
					</li>
					<li className="rake-faq-list-head">
						<b>High (1.5bb cap):</b>
						<ul className="rake-faq-sublist">
							<li>Stars 200</li>
							<li>GG 500</li>
						</ul>
					</li>
					<li className="rake-faq-list-head">
						<b>Medium (1bb cap):</b>
						<ul className="rake-faq-sublist">
							<li>Bodog 500</li>
							<li>GG 1000</li>
						</ul>
					</li>
					<li className="rake-faq-list-head">
						<b>Low (0.5bb cap):</b>
						<ul className="rake-faq-sublist">
							<li>Stars 500</li>
							<li>Bodog 1000</li>
							<li>GG 5000</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	);
}

// https://cdn.discordapp.com/attachments/545357562858700810/950885730522107994/unknown.png
