import React from "react";

export default function CalendarMonth({ user, month, status, handleResultViewUpdate, viewStudentSummary }) {
	const statusMessage = status === "final" ? "View Results" : status === "editable" ? "Edit Results" : "Input Results";

	return (
		<div className="acc-calendar-month">
			<h4>{`${month}`}</h4>
			<button onClick={() => handleResultViewUpdate()} disabled={status === "disabled"}>
				{statusMessage}
			</button>
			<button onClick={() => viewStudentSummary()}>Student Summary</button>
		</div>
	);
}
