import React, { useState } from "react";

export default function AddSiteOverlay({ displayOverlay = false, addSite, closeSiteOverlay }) {
	const [siteName, setSiteName] = useState("");
	const [settlementName, setSettlementName] = useState("");
	const [currency, setCurrency] = useState("USD");

	const handleButtonClick = (e) => {
		e.preventDefault();
		if (siteName) addSite(siteName, currency);
		if (settlementName) addSite(settlementName, currency, true);
		setSiteName("");
	};

	const currencyOptions = ["USD", "CAD", "GBP", "EUR", "KRW", "CNY"];

	return (
		<div className={`screenshot-overlay ${displayOverlay ? "" : "display-none"}`} onClick={() => closeSiteOverlay()}>
			<div className={`add-site-cnt`} onClick={(e) => e.stopPropagation()}>
				<h4>Add New Site</h4>
				<form onSubmit={(e) => handleButtonClick(e)}>
					<span>Site Name:</span>
					<input id="site-input" value={siteName} onChange={(e) => setSiteName(e.target.value)} autoComplete="off" />
					<div>
						<span>Currency:</span>
						<select value={currency} onChange={(e) => setCurrency(e.target.value)}>
							{currencyOptions.map((cur) => (
								<option key={cur} value={cur}>
									{cur}
								</option>
							))}
						</select>
					</div>
					<button type="submit">Add Site</button>
				</form>

				<h4 className="add-settlement-header">Add One-time Settlement Amount</h4>
				<form onSubmit={(e) => handleButtonClick(e)}>
					<span>Label:</span>
					<input id="site-input" value={settlementName} onChange={(e) => setSettlementName(e.target.value)} autoComplete="off" />
					<div>
						<span>Currency:</span>
						<select value={currency} onChange={(e) => setCurrency(e.target.value)}>
							{currencyOptions.map((cur) => (
								<option key={cur} value={cur}>
									{cur}
								</option>
							))}
						</select>
					</div>
					<button type="submit">Add Amount</button>
				</form>
			</div>
		</div>
	);
}
