import { cloneDeep } from "lodash";
import getSeatNamesFromNumberOfSeats from "./getSeatNamesFromNumberOfSeats";

export default function getStarterActionSummary(treeInfo: Record<any, any>) {
	if (treeInfo.hasOwnProperty("customActionSummary")) return cloneDeep(treeInfo.customActionSummary);

	const starterActionSummary: actionSummary = {};
	const seatNames = getSeatNamesFromNumberOfSeats(treeInfo.seats);
	for (const seat of seatNames) {
		let amountCommitted = 0;
		let raiseLevel = 0;
		if (seat === "sb") {
			amountCommitted = 1;
			raiseLevel = 1;
		} else if (seat === "bb") {
			amountCommitted = 2;
			raiseLevel = 2;
		} else if (treeInfo.straddle && seat === seatNames[0]) {
			amountCommitted = 4;
			raiseLevel = 3;
		}
		starterActionSummary[seat] = { active: false, folded: false, amountCommitted, raiseLevel };
	}

	return starterActionSummary;
}
