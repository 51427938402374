import React from "react";
import { Link } from "react-router-dom";
import userIsAdmin from "./Shared/functions/userIsAdmin";
import userIsBasic from "./Shared/functions/userIsBasic";
import userIsUploader from "./Shared/functions/userIsUploader";
import { UserContext } from "./UserContext";

class NavBar extends React.Component {
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			admin: false,
			uploader: false,
			basic: true,
		};
	}

	async componentDidMount() {
		// if (await userIsAdmin(this.context.uid)) this.setState({ admin: true });
		// if (await userIsUploader(this.context.uid)) this.setState({ uploader: true });
		const admin = await userIsAdmin(this.context.uid);
		const uploader = await userIsUploader(this.context.uid);
		const basic = await userIsBasic(this.context.uid);
		this.setState({ admin, uploader, basic });
	}

	render() {
		const path = window.location.pathname;
		return (
			<div className={`global-nav ${path === "/" ? "" : "mobile-hide"}`}>
				<h2 className="nav-heading">PM</h2>
				<Link to="/" className="nav-item" onClick={this.props.logout}>
					Logout
				</Link>
				<Link to="/feedback" className="nav-item">
					Feedback
				</Link>
				<Link to="/settings" className="nav-item">
					Settings
				</Link>
				<Link to="/tools" className={`nav-item mobile-hide ${!this.state.basic ? "" : "display-none"}`}>
					Tools
				</Link>
				<Link to="/trees" className={`nav-item mobile-hide ${!this.state.basic ? "" : "display-none"}`}>
					Tree Manager
				</Link>
				<Link
					to="/upload"
					id="upload-link"
					className={`nav-item mobile-hide ${this.state.admin || this.state.uploader ? "" : "display-none"}`}>
					Upload Tree
				</Link>
			</div>
		);
	}
}

export default NavBar;
