
export const _SRP_IP = `#AllinThreshold#75
#AddAllinOnlyIfLessThanThisTimesThePot#500
#FlopConfig.RaiseSize#50, 100
#TurnConfig.BetSize#33, 75, 150
#TurnConfig.RaiseSize#50 2e
#TurnConfig.AddAllin#True
#TurnConfig.DonkBetSize#25
#RiverConfig.BetSize#33,75,150
#RiverConfig.RaiseSize#50 100
#RiverConfig.AddAllin#True
#RiverConfig.DonkBetSize#25
#FlopConfigIP.BetSize#33
#FlopConfigIP.RaiseSize#33
#TurnConfigIP.BetSize#33, 75, 150
#TurnConfigIP.RaiseSize#50 2e
#TurnConfigIP.AddAllin#True
#RiverConfigIP.BetSize#70, 100, 150
#RiverConfigIP.RaiseSize#50 100
#RiverConfigIP.AddAllin#True`

export const _SRP_OOP = `#AllinThreshold#75
#AddAllinOnlyIfLessThanThisTimesThePot#500
#FlopConfig.BetSize#33
#FlopConfig.RaiseSize#50, 100
#TurnConfig.BetSize#33, 75, 150
#TurnConfig.RaiseSize#50 2e
#TurnConfig.AddAllin#True
#TurnConfig.DonkBetSize#25
#RiverConfig.BetSize#33,75,150
#RiverConfig.RaiseSize#50 100
#RiverConfig.AddAllin#True
#RiverConfig.DonkBetSize#25
#FlopConfigIP.BetSize#50
#FlopConfigIP.RaiseSize#50
#TurnConfigIP.BetSize#33, 75, 150
#TurnConfigIP.RaiseSize#50 2e
#TurnConfigIP.AddAllin#True
#RiverConfigIP.BetSize#70, 100, 150
#RiverConfigIP.RaiseSize#50 100
#RiverConfigIP.AddAllin#True`

// there's two fairly different bundles of text beneath the ranges here 
export const _3BP_IP = `#AllinThreshold#75
#AddAllinOnlyIfLessThanThisTimesThePot#500
#FlopConfig.RaiseSize#50, 2.2x
#FlopConfig.AddAllin#True
#FlopConfig.DonkBetSize#33
#TurnConfig.BetSize#33 66 100
#TurnConfig.RaiseSize#50, 2.2x
#TurnConfig.AddAllin#True
#TurnConfig.DonkBetSize#30
#RiverConfig.BetSize#33 66 100
#RiverConfig.RaiseSize#50, 2.2x
#RiverConfig.AddAllin#True
#RiverConfig.DonkBetSize#30
#FlopConfigIP.BetSize#33
#FlopConfigIP.RaiseSize#50, 2.2x
#FlopConfigIP.AddAllin#True
#TurnConfigIP.BetSize#33 66 100
#TurnConfigIP.RaiseSize#50, 2.2x
#TurnConfigIP.AddAllin#True
#RiverConfigIP.BetSize#33 66 100
#RiverConfigIP.RaiseSize#50, 2.2x
#RiverConfigIP.AddAllin#True`

export const _3BP_OOP = `#AllinThreshold#75
#AddAllinOnlyIfLessThanThisTimesThePot#500
#FlopConfig.BetSize#33 66
#FlopConfig.RaiseSize#50, 2.2x
#FlopConfig.AddAllin#True
#FlopConfig.DonkBetSize#33
#TurnConfig.BetSize#33 66 100
#TurnConfig.RaiseSize#50, 2.2x
#TurnConfig.AddAllin#True
#TurnConfig.DonkBetSize#30
#RiverConfig.BetSize#33 66 100
#RiverConfig.RaiseSize#50, 2.2x
#RiverConfig.AddAllin#True
#RiverConfig.DonkBetSize#30
#FlopConfigIP.BetSize#33 66
#FlopConfigIP.RaiseSize#50, 2.2x
#FlopConfigIP.AddAllin#True
#TurnConfigIP.BetSize#33 66 100
#TurnConfigIP.RaiseSize#50, 2.2x
#TurnConfigIP.AddAllin#True
#RiverConfigIP.BetSize#33 66 100
#RiverConfigIP.RaiseSize#50, 2.2x
#RiverConfigIP.AddAllin#True`

export const _4BP = `#AllinThreshold#100
#AddAllinOnlyIfLessThanThisTimesThePot#500
#FlopConfig.BetSize#30
#FlopConfig.RaiseSize#52, 2x
#FlopConfig.AddAllin#True
#TurnConfig.BetSize#33 66 2e
#TurnConfig.RaiseSize#30, 2x
#TurnConfig.AddAllin#True
#TurnConfig.DonkBetSize#30
#RiverConfig.BetSize#33, 66 100
#RiverConfig.RaiseSize#52, 2x
#RiverConfig.AddAllin#True
#RiverConfig.DonkBetSize#30
#FlopConfigIP.BetSize#25 33 50
#FlopConfigIP.RaiseSize#52, 2x
#FlopConfigIP.AddAllin#True
#TurnConfigIP.BetSize#33 66 2e
#TurnConfigIP.RaiseSize#30, 2x
#TurnConfigIP.AddAllin#True
#RiverConfigIP.BetSize#33, 66 100
#RiverConfigIP.RaiseSize#52, 2x
#RiverConfigIP.AddAllin#True`

export const _5BP = `#AllinThreshold#100
#AddAllinOnlyIfLessThanThisTimesThePot#500
#FlopConfig.BetSize#30
#FlopConfig.RaiseSize#52, 2x
#FlopConfig.AddAllin#True
#TurnConfig.BetSize#33 66 2e
#TurnConfig.RaiseSize#30, 2x
#TurnConfig.AddAllin#True
#TurnConfig.DonkBetSize#30
#RiverConfig.BetSize#33, 66 100
#RiverConfig.RaiseSize#52, 2x
#RiverConfig.AddAllin#True
#RiverConfig.DonkBetSize#30
#FlopConfigIP.BetSize#25 33 50
#FlopConfigIP.RaiseSize#52, 2x
#FlopConfigIP.AddAllin#True
#TurnConfigIP.BetSize#33 66 2e
#TurnConfigIP.RaiseSize#30, 2x
#TurnConfigIP.AddAllin#True
#RiverConfigIP.BetSize#33, 66 100
#RiverConfigIP.RaiseSize#52, 2x
#RiverConfigIP.AddAllin#True`
