// React
import React from "react";
// SVG
import { ReactComponent as Table } from "../../../css/images/rt-table-bg.svg";
// RT-specific
import PlayerSpot from "./PlayerSpot";
import getHandVisuals from "../functions/getHandVisuals";
// Helpers
import getSeatNamesFromNumberOfSeats from "../../Shared/functions/getSeatNamesFromNumberOfSeats";
import { transformCoordinates, transformCoordinatesFullRing } from "../../../constants";
import { lastElement } from "../../../helpers";

export default function TableView({
	treeID,
	treeInfo,
	spot,
	actionSummary,
	hand,
	toggleRangeDisplay,
	prevFreqDisplaySetting,
	prevFreq,
	handleMouseEnter,
	handleMouseLeave,
}) {
	const getPlayerSpotArray = () => {
		const pos = lastElement(spot.split(","));
		const seats = getSeatNamesFromNumberOfSeats(treeInfo.seats);
		let sortedSeats = [...seats];
		let playerSpotArray = [];

		for (let i = 0; i < seats.length; i++) {
			if (seats[i] === pos) break;
			sortedSeats.push(sortedSeats.shift());
		}

		for (let i = 0; i < sortedSeats.length; i++) {
			playerSpotArray.push(
				<PlayerSpot
					key={i}
					seatNumber={i}
					spot={spot}
					hand={hand}
					coordinates={treeInfo.seats <= 6 ? transformCoordinates[`spot${i}`] : transformCoordinatesFullRing[`spot${i}`]}
					position={sortedSeats[i]}
					button={sortedSeats[i] === "bu" || (sortedSeats[i] === "sb" && seats.length === 2)}
					playerActionSummary={actionSummary[sortedSeats[i]]}
					stackDepth={treeInfo.stackDepth}
					fullRing={treeInfo.seats > 6}
					tree={treeID}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					toggleRangeDisplay={toggleRangeDisplay}
					prevFreqDisplaySetting={prevFreqDisplaySetting}
					prevFreq={prevFreq}
				/>
			);
		}
		return playerSpotArray;
	};

	const playerSpotArray = getPlayerSpotArray();
	const handVisuals = getHandVisuals(hand, treeInfo.seats > 6 ? true : false);
	return (
		<div className="table-view-container">
			<svg className="table-view" viewBox="0 0 1000 650">
				<Table y="5%" width="100%" height="75%" onClick={toggleRangeDisplay} />
				{playerSpotArray}
				{handVisuals}
				{/* {hoverRange} */}
				{/* {Object.values(clickedRangeDisplays)} */}
			</svg>
		</div>
	);
}
