import base from "../../base";
import userIsAdmin from "../Shared/functions/userIsAdmin";

export default async function getUserLastLogin(uid) {
	if (!(await userIsAdmin(uid))) return;

	const allUsers = await base.fetch("users", { context: this });

	const userListToSort = [];
	for (const user in allUsers) {
		userListToSort.push([user, allUsers[user].name, allUsers[user].lastLogin]);
	}
	userListToSort.sort((a, b) => {
		return Date.parse(b[2]) - Date.parse(a[2]);
	});

	console.log(userListToSort);
}
