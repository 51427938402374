import { removeNonDigits, removeNumbers } from "../../../helpers";
import { months } from "../../../constants";

// sorts most recent month first

export default function sortDateHistory(monthHistory) {
	const sortedDateHistory = monthHistory.sort((a, b) => {
		const yearA = removeNonDigits(a);
		const yearB = removeNonDigits(b);
		const monthA = removeNumbers(a);
		const monthB = removeNumbers(b);

		if (yearA != yearB) return yearB - yearA;
		return months.indexOf(monthB) - months.indexOf(monthA);
	});

	return sortedDateHistory;
}
