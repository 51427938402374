import React from "react";
import { useDropzone } from "react-dropzone";
import formatHoldemRangeData from "../functions/formatHoldemRangeData";
import formatPLORangeData from "../functions/formatPLORangeData";
import getActionKeyFromFilename from "../functions/getActionKeyFromMKVFilename";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function UploadDropzone(props) {
	function handleDragEnter(e) {
		if (e.target.classList.contains("dropzone")) {
			e.target.classList.add("dragging");
		}
	}
	function handleDragLeave(e) {
		if (e.target.classList.contains("dropzone") && e.relatedTarget.tagName != "EM") {
			e.target.classList.remove("dragging");
		}
	}

	function finishDrop(formattedRangeData, availableRanges, fileNames) {
		props.updateAvailableRanges(availableRanges);
		props.updateRangeDataToUpload(formattedRangeData);
		props.updateUniqueFilenames(fileNames);
		props.toggleUploading();
	}

	function handleDrop(acceptedFiles, rejectedFiles, e) {
		document.querySelector(".dragging").classList.remove("dragging");
		const numberOfFiles = acceptedFiles.length;
		let processedFiles = 0;
		let formattedRangeData = {};
		let availableRanges = [];
		let fileNames = [];
		props.toggleUploading();
		acceptedFiles.forEach((file) => {
			fileNames.push(file.name);
			if (file.name.includes(".0.rng") || file.name === "0.rng") {
				processedFiles += 1;
				if (processedFiles === numberOfFiles) {
					finishDrop(formattedRangeData, availableRanges, fileNames);
				}
				return;
			} else {
				const reader = new FileReader();
				reader.onabort = () => console.log(`file ${file.name}: reading was aborted`);
				reader.onerror = () => console.log(`file ${file.name}: reading has failed`);
				reader.onloadend = () => {
					const fileText = reader.result;
					const actionKey = getActionKeyFromFilename(file.name.replace(".rng", ""), props.seats);
					const fileData = props.gameType === "NLHE" ? formatHoldemRangeData(fileText) : formatPLORangeData(fileText);
					availableRanges.push(actionKey);
					formattedRangeData[actionKey] = fileData;
					processedFiles += 1;
					if (processedFiles === numberOfFiles) {
						finishDrop(formattedRangeData, availableRanges, fileNames);
					}
				};
				reader.readAsText(file);
			}
		});
	}

	const { getRootProps, getInputProps } = useDropzone({
		accept: ".rng",
		onDrop: (acceptedFiles, rejectedFiles, e) => handleDrop(acceptedFiles, rejectedFiles, e),
	});

	let display;
	let text =
		props.uniqueRangesUploadCount.length > 0
			? `
			${props.uniqueRangesUploadCount} non-fold range files added in this drop.
			Ready to upload this batch of files!`
			: "Drag 'n' drop your folder here, or click to select";

	if (props.uploadingInProgress) {
		display = <Loader className="loading-spinner" type="Puff" color="grey" height={100} width={100} />;
	} else {
		display = (
			<section className="upload-container" hidden={props.uploadingInProgress}>
				<div
					{...getRootProps({
						className: "dropzone",
						onDragEnter: (e) => handleDragEnter(e),
						onDragLeave: (e) => handleDragLeave(e),
					})}>
					<input id="upload-dropzone" {...getInputProps()} />
					<em>{text}</em>
				</div>
			</section>
		);
	}

	//TODO: hPTfmzen

	return (
		<div className="upload-form">
			<h2 className="page-heading">
				Step Two: Upload your exported .mkv folder <br />
			</h2>
			<h4>(It may take a few minutes to process)</h4>
			{display}
			<div>
				<button onClick={props.backToDetails}>Back</button>
				<button onClick={props.uploadMetadataOnly}>Upload Metadata Only</button>
				<button onClick={props.finalizeTreeSubmission} disabled={!props.isMetadataUploaded}>
					Upload & Submit Tree
				</button>
			</div>
		</div>
	);
}

export default UploadDropzone;

// TODO: add progress bar/text on loading swirl - test to see if this adds a significant amount of time

// Unique filenames processed  --- to be able to check that you dragged all ranges into the folder
// Change updating available ranges to add non-duplicates to the list
// Maybe make note of # of duplicate drag/drops?
