import { betNames } from "../../../constants";
import { getPositionFromAction } from "../../../helpers";
import onMobile from "../../Mobile/onMobile";

export default function getActionDescription(actionString, straddlePos = null) {
	if (!actionString) {
		if (!onMobile()) return "Left Click: Input Action. Right Click: Launch a Range Display";
		else return "Touch to Input Action. Long Press to launch Range Display";
	}
	let actionDescription = "";
	let raiseLevel = 0;
	let isASqueeze = false;
	actionString.split(",").forEach((action) => {
		let actionText;
		const pos = getPositionFromAction(action) === straddlePos ? "Straddle" : getPositionFromAction(action).toUpperCase();
		const actionKey = action.replace(pos, "");
		if (actionDescription !== "") actionDescription += ", ";
		if (actionKey === "3") actionText = "All-in";
		else if (actionKey === "1") {
			actionText = "Call";
			isASqueeze = true;
		} else if (isASqueeze) {
			actionText = "Squeeze";
			raiseLevel += 1;
			isASqueeze = false;
		} else {
			actionText = betNames[raiseLevel];
			raiseLevel += 1;
		}
		actionDescription += pos + " " + actionText;
	});

	return actionDescription;
}
