import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import base from "../../../base";
import DealShareholderInput from "./DealShareholderInput";
import DealUserSelect from "./DealUserSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import validateShareholderBreakdown from "../functions/validateShareholderBreakdown";
import { nanoid } from "nanoid";
import { UserContext } from "../../UserContext";
import userIsAdmin from "../../Shared/functions/userIsAdmin";

export default function DealCreator({ returnToCalendar }) {
	const context = useContext(UserContext);

	const [loading, setLoading] = useState(true);
	const [accountingUsers, setAccountingUsers] = useState({});
	const [dealType, setDealType] = useState("action");
	const [subject, setSubject] = useState(null);
	const [effectiveDate, setEffectiveDate] = useState(new Date());

	const [shareholderBreakdown, setShareholderBreakdown] = useState({});
	const [shareholderSelectValue, setShareholderSelectValue] = useState(null);

	const dealID = nanoid(5);

	useEffect(() => {
		getAccountingUsers();
	}, []);

	const getAccountingUsers = async () => {
		const accountingUsers = await base.fetch(`accountingUsers`, { context: this });
		setAccountingUsers(accountingUsers);
		setLoading(false);
	};

	const addShareholder = (shareholder) => {
		const shb = cloneDeep(shareholderBreakdown);
		if (shareholder && !Object.keys(shb).includes(shareholder)) shb[shareholder] = { backing: 0, action: 0, bankroll: 0 };
		setShareholderBreakdown(shb);
		setShareholderSelectValue(null);
	};
	const removeShareholder = (shareholder) => {
		if (shareholder === subject) return;
		const shb = cloneDeep(shareholderBreakdown);
		delete shb[shareholder];
		setShareholderBreakdown(shb);
	};
	const updateShareholderBreakdown = (value, shareholder, type) => {
		const shb = cloneDeep(shareholderBreakdown);
		shb[shareholder][type] = value;
		setShareholderBreakdown(shb);
	};

	const shareholderComponents = Object.keys(shareholderBreakdown).map((person) => (
		<DealShareholderInput
			id={person}
			name={accountingUsers[person]}
			dealType={dealType}
			updateShareholderBreakdown={updateShareholderBreakdown}
			removeShareholder={removeShareholder}
		/>
	));

	const submitDealCreationForm = (e) => {
		e.preventDefault();
		if (!Object.keys(shareholderBreakdown).length) {
			alert("Add at least one shareholder");
			return;
		}
		if (validateShareholderBreakdown(shareholderBreakdown, dealType)) uploadDealToDatabase();
	};

	const uploadDealToDatabase = async () => {
		if (!(await userIsAdmin(context.uid))) {
			console.error("Permission Denied");
			return;
		}
		const subjectDeals = await base.fetch(`accountingDeals/users/${subject}/deals`, { context: this });
		const newDealIsDefault = !Object.values(subjectDeals).includes("default");
		const dateString = effectiveDate.toDateString();
		const dealData = {
			active: true,
			effectiveDate: dateString.substr(dateString.indexOf(" ") + 1),
			shareholderBreakdown: shareholderBreakdown,
			subject: subject,
			dealType: dealType,
		};
		base.post(`accountingDeals/deals/${dealID}`, { data: dealData });
		base.update(`accountingDeals/users/${subject}/deals`, { data: { [dealID]: `${newDealIsDefault ? "default" : "active"}` } });
		Object.keys(shareholderBreakdown).forEach((uid) => {
			if (uid === subject) return;
			base.update(`accountingDeals/users/${uid}/shares`, { data: { [dealID]: "active" } });
		});
		alert("Deal Created");
		returnToCalendar();
	};

	const selectDealSubject = (user) => {
		setSubject(user);
		addShareholder(user);
	};

	if (loading) return <></>;
	return (
		<form className="acc-deal-creator" onSubmit={(e) => submitDealCreationForm(e)}>
			<h2>Create New Deal</h2>
			<div className="adc-dealtype-cnt">
				<h4>Deal Type:</h4>
				<select defaultValue={dealType} onChange={(e) => setDealType(e.target.value)}>
					<option value="action">Action</option>
					<option value="backing">Backing</option>
					<option value="hybrid">Hybrid</option>
				</select>
				<h4>Deal Subject:</h4>
				<DealUserSelect users={accountingUsers} value={subject} selectUser={selectDealSubject} />
				<h4>Effective Date:</h4>
				<DatePicker selected={effectiveDate} onChange={setEffectiveDate} />
			</div>
			<div className={`adc-action-holders-cnt ${subject ? "" : "display-none"}`}>
				<h3>Shareholders Breakdown</h3>
				<div className="adc-add-shareholder-cnt">
					<h4>Add Shareholder:</h4>
					<DealUserSelect users={accountingUsers} value={shareholderSelectValue} selectUser={addShareholder} />
				</div>
				{shareholderComponents}
			</div>
			<button className={`adc-submit-button ${subject ? "" : "display-none"}`}>Create Deal</button>
		</form>
	);
}
