import React from "react";
import emailjs from "emailjs-com";
import { withRouter } from "react-router-dom";
import { UserContext } from "./UserContext";
import base from "../base";

class Feedback extends React.Component {
	static contextType = UserContext;
	constructor(props) {
		super(props);
		this.state = {
			subject: "feedback",
			content: "",
		};
	}

	handleSubjectChange = (e) => this.setState({ subject: e.target.value });
	handleContentChange = (e) => this.setState({ content: e.target.value });

	sendEmail = async (e) => {
		e.preventDefault(); //This is important, email won't send without it

		const user_id = await base.fetch(`emailjs/user_id`, { context: this });

		const data = {
			...this.state,
			name: this.context.name,
		};

		emailjs.send("service_gmail", "template_feedback", data, user_id).then(
			(result) => {
				alert("Feedback sent. Thank you!");
				this.props.history.push(``);
			},
			(error) => {
				console.log(error.text);
			}
		);
	};

	render() {
		return (
			<form className="feedback-form absolute-center" onSubmit={this.sendEmail}>
				<h2>Submit Feedback Form</h2>
				<div className="feedback-ele">
					<label>Subject: </label>
					<select name="subject" onChange={this.handleSubjectChange}>
						<option value="feedback">Feedback</option>
						<option value="bug-report">Bug Report</option>
						<option value="idea">Idea / Feature Request</option>
					</select>
				</div>
				<div className="feedback-ele">
					<div>
						<label>Message: </label>
					</div>
					<div>
						<textarea className="feedback-box" onChange={this.handleContentChange} />
					</div>
				</div>
				<input type="submit" value="Send" />
			</form>
		);
	}
}

export default withRouter(Feedback);
