import { lastElement, removePositionText } from "../../../helpers";

export default function sortAvailableActions(actions) {
	const sortedActions = actions.sort((a, b) => {
		const actionA = removePositionText(lastElement(a.split(",")));
		const actionB = removePositionText(lastElement(b.split(",")));
		if (actionA === "1" || (actionA === "3" && actionB !== "1")) return 1;
		else if (actionB === "1" || (actionB === "3" && actionA !== "1")) return -1;
		else return 0;
	});

	return sortedActions;
}
