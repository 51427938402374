import React, { useEffect, useState } from "react";
import base from "../../../base";
import DealDetails from "./DealDetails";
import { cloneDeep } from "lodash";
import getActiveDeals from "../functions/getActiveDeals";

export default function DealsOverview({ user, dealData, dealStatus, accountingUsers, getDealStatus }) {
	const activeDealIDs = getActiveDeals(dealStatus);

	const dealDisplays = activeDealIDs.map((id) => (
		<DealDetails
			dealInfo={dealData[id]}
			accountingUsers={accountingUsers}
			defaultDeal={dealStatus[id] === "default"}
			makeDealDefault={() => makeDealDefault(id)}
			retireDeal={() => retireDeal(id)}
		/>
	));

	const makeDealDefault = async (id) => {
		const newDealStatus = cloneDeep(dealStatus);
		Object.keys(newDealStatus).forEach((deal) => {
			if (newDealStatus[deal] === "default") newDealStatus[deal] = "active";
			else if (deal === id) newDealStatus[deal] = "default";
		});
		base.update(`accountingDeals/users/${user}/deals`, { data: newDealStatus }).then(() => getDealStatus());
	};

	const retireDeal = async (id) => {
		if (
			!confirm(
				"Do you want to retire this deal? \nIt will no longer be able to apply to future month's accounting (but the information will still be kept & used to calculate past results)"
			)
		)
			return;
		const newDealStatus = cloneDeep(dealStatus);
		newDealStatus[id] = "inactive";
		base.update(`accountingDeals/deals/${id}`, { data: { active: false } });
		Object.keys(dealData[id].shareholderBreakdown).forEach((person) => {
			if (person !== user) base.update(`accountingDeals/users/${person}/shares`, { data: { [id]: "inactive" } });
		});
		base.update(`accountingDeals/users/${user}/deals`, { data: newDealStatus }).then(() => getDealStatus());
		alert("Deal retired");
	};

	return (
		<div className="deals-overview-cnt">
			<h2>Deals Overview</h2>
			<h3>Active Deals</h3>
			{dealDisplays}
		</div>
	);
}
