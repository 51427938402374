import { randomElement, lastElement, shuffle } from "../../../helpers";
import { suits, PLOTrainingHandsArray } from "../../../constants";
import weightedRandomDeal from "./weightedRandomDeal";

export default function getPLOHoleCards(possibleHands = PLOTrainingHandsArray, previousFrequencies = null) {
	// If proportional deal setting is on and it applies to the current situation,
	// Use a weighted random roll to select the hand. Otherwise, uniform random select
	let hand;
	if (previousFrequencies) hand = weightedRandomDeal(possibleHands, previousFrequencies);
	else hand = randomElement(possibleHands);

	const cardArray = [];
	const suitArray = shuffle(suits);
	let currentSuit = suitArray[0];
	let changeSuit = true;
	// randomly select suits for cards based on suitedness brackets
	// // cases: rainbow, 1 suited, 2 suited
	for (let i = 0; i < hand.length; i++) {
		if (hand.charAt(i) === "(") changeSuit = false;
		else if (hand.charAt(i) === ")") changeSuit = true;
		else cardArray.push(`${hand[i]}${currentSuit}`);
		if (changeSuit) currentSuit = suitArray[suitArray.indexOf(currentSuit) + 1];
	}
	//

	return [hand, ...shuffle(cardArray)];
}
