import getHandsInRadius from "./getHandsInRadius";

export default function isHandRelevant(hand, range, tightHandSelect = false) {

	const frequency = range[hand] ? "1.0" : "0";
	const handsInRadius = getHandsInRadius(hand, tightHandSelect);

	// isHandRelevant checks if any hand in the radius has a different frequency than the one selected; if not, the hand is presumed to be trivially easy
	const isHandRelevant = handsInRadius.some((rHand) => {
		const freq = range[rHand] ? range[rHand] : "0";
		return frequency !== freq;
	});

	return isHandRelevant;
}
