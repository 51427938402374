import { getPositionFromAction } from "../../../helpers";
import sortAvailableActions from "./sortAvailableActions";

export default function getAvailableActions(availableRanges, actionString = "") {
	let availableActions = availableRanges.reduce((acc, range) => {
		// Check to see if the range is one that is possible to follow the current input/actionString, if not return the accumulator
		if (!range.startsWith(actionString ? actionString + "," : actionString)) return acc;
		else if (actionString === "" && range.split(",").length !== 1) return acc;
		else if (actionString !== "" && range.split(",").length !== actionString.split(",").length + 1) return acc;
		// If it is a possible follow up action, sort it into the availableActions object by position
		const position = getPositionFromAction(range.split(",").pop());
		return { ...acc, [position]: [...(acc[position] || []), range] };
	}, {});
	Object.keys(availableActions).forEach((position) => {
		availableActions[position] = sortAvailableActions(availableActions[position]);
	});
	return availableActions;
}
