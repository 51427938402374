import React from "react";
import PlayerSpotRangeCell from "./PlayerSpotRangeCell";
import addScore from "../../Shared/functions/addScore";
import { allHandsMatrix } from "../../../constants";
import { getAccuracy } from "../../../helpers";
import { round } from "lodash";

export default function PlayerSpotRangeChart({ reps, repData }) {
	const rangeScores = {};
	const rangeAccuracies = {};
	const isPLO = repData[Object.keys(repData)[0]].hand.length > 3;

	Object.keys(reps).forEach((rep) => {
		const hand = repData[rep].hand;
		rangeScores[hand] = addScore(rangeScores[hand] ? rangeScores[hand] : "0/0", reps[rep]);
	});

	Object.keys(rangeScores).forEach((hand) => (rangeAccuracies[hand] = getAccuracy(rangeScores[hand])));

	let display = <h5 id="player-spot-chart-plo-error">Not available for PLO</h5>;
	if (!isPLO) {
		display = (
			<div className="player-spot-range-chart">
				{allHandsMatrix.map((row, index) => (
					<div className="range-row" key={index}>
						{row.map((hand) => {
							const freq = rangeAccuracies.hasOwnProperty(hand) ? round(rangeAccuracies[hand] / 100, 2) : "-";
							return <PlayerSpotRangeCell key={hand} hand={hand} freq={freq} />;
						})}
					</div>
				))}
			</div>
		);
	}

	return display;
}
