import { round } from "lodash";

export default function getRaiseSize(size, chipSize) {
	if (size === "2") return "Pot";
	else if (size === "4") return "1/2 Pot";
	else if (size === "5") return "Min";
	else if (size === "9") return "3/4 Pot";
	else if (size.startsWith("40") && size.length !== 2) {
		let rs = size.substring(2);
		if (rs.charAt(0) === "0") rs = rs.substring(1);
		if (rs === "50") return "1/2 Pot";
		if (rs === "100") return "Pot";
		else return rs + "%";
	} else if (size.length === 2 || size.length === 3 || size.length === 4) {
		const rs = round(((parseFloat(size) - 11) * chipSize) / 2, 2);
		return rs + "bb";
	} else return "x";
	// possible other cases: 5
}
