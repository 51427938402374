import { capitalize } from "lodash";
import React from "react";
import { removeDuplicates } from "../../../helpers";
import formatDollarAmount from "../functions/formatDollarAmount";

export default function DealShareSummary({ user, dealData, dealProfit = 0, dealTransactions = 0, currency, accountingUsers }) {
	const sharepeople = removeDuplicates([dealData.subject, ...Object.keys(dealData.shareholderBreakdown)]); // puts the subject of the deal at the top

	// Total Profit Share
	// Share of Net transaction (amount owed for action)
	// Share of backing deal profit (amount owed for backing)
	// Share owed otherwise

	// for action share deal

	if (dealData.dealType === "settlement") {
		const profit = dealProfit > 0 ? true : false;
		return (
			<div className="deal-share-summary-cnt">
				<h3>Total Other Settlement Amount</h3>
				<div>
					{`${accountingUsers[user]}`} {`${profit ? "owed" : "owes"}`}:{" "}
					<span className={`profit-${profit ? "green" : "red"} bold`}>{formatDollarAmount(dealProfit)}</span> {currency}
				</div>
			</div>
		);
	}

	//dealType IS backing, month was a loss
	else if (dealData.dealType === "backing" && dealProfit < 0) {
		return (
			<div className="deal-share-summary-cnt">
				<h3>Deal Breakdown for {`${dealData.effectiveDate} ${capitalize(dealData.dealType)} Deal`}</h3>
				<div>
					Amount To Be Added to Makeup: <span className="profit-red bold">{formatDollarAmount(dealProfit)}</span> {currency}
				</div>
			</div>
		);
	} // if (dealData.dealType !== "backing")
	else
		return (
			<div className="deal-share-summary-cnt">
				<h3>Owing Breakdown for {`${dealData.effectiveDate} ${capitalize(dealData.dealType)} Deal`}</h3>
				{sharepeople.map((person, i) => {
					const shares = dealData.shareholderBreakdown[person];
					console.log(dealData);
					console.log(person);
					const soleShareholder = parseInt(shares.bankroll) === 100;
					const profitShare = ((parseFloat(shares.backing) + parseFloat(shares.action)) / 100) * dealProfit;
					const changeInEquity = ((dealProfit - dealTransactions) * shares.bankroll) / 100;
					const amountOwed = profitShare - changeInEquity;

					return (
						<div key={i} className="deal-share-amount-owed-cnt">
							<h4>{accountingUsers[person]}</h4>
							<div className={`${!shares.bankroll || parseFloat(shares.bankroll) === 0 ? "display-none" : ""}`}>
								Result Share:{" "}
								<span className={profitShare > 0 ? "profit-green" : profitShare < 0 ? "profit-red" : ""}>
									{formatDollarAmount(profitShare)}
								</span>{" "}
								{currency}
							</div>
							<div className={`${!shares.bankroll || parseFloat(shares.bankroll) === 0 || soleShareholder ? "display-none" : ""}`}>
								Change in Bankroll Equity:{" "}
								<span className={changeInEquity > 0 ? "profit-green" : changeInEquity < 0 ? "profit-red" : ""}>
									{formatDollarAmount(changeInEquity)}
								</span>{" "}
								{currency}
							</div>
							<div className={`${soleShareholder ? "display-none" : ""}`}>
								{amountOwed > 0 ? "Amount Owed" : "Amount Owing"}:{" "}
								<span className={amountOwed > 0 ? "profit-green bold" : amountOwed < 0 ? "profit-red bold" : "bold"}>
									{formatDollarAmount(amountOwed)}
								</span>{" "}
								{currency}
							</div>
						</div>
					);
				})}
			</div>
		);
}

// Amount Owed = Total Profit - Change in Bankroll Equity
// Change in bankroll equity = Total Profit - Net Transactions
