import { getPositionsFromActionArray } from "../../../helpers";
import getPositionFromSpot from "../../Shared/functions/getPositionFromSpot";
import getSeatNamesFromNumberOfSeats from "../../Shared/functions/getSeatNamesFromNumberOfSeats";

//TODO: REMOVE SPREAD OPERATOR?

export default function sortRepDataByPosition(scenarioRepData) {
	const repDataByPosition = {};
	Object.keys(scenarioRepData).forEach((rep) => {
		const spot = scenarioRepData[rep].spot;
		const allPositions = getPositionsFromActionArray(spot.split(","));
		const hero = getPositionFromSpot(spot);
		const opponents = allPositions.filter((pos) => pos !== hero).toString();
		if (opponents !== "") {
			if (!repDataByPosition[hero]) repDataByPosition[hero] = {};
			repDataByPosition[hero] = { ...repDataByPosition[hero], [opponents]: [...(repDataByPosition[hero][opponents] || []), rep] };
		} else {
			repDataByPosition[hero] = [...(repDataByPosition[hero] || []), rep];
		}
	});
	const seats = getSeatNamesFromNumberOfSeats(9);
	let opponentSortedRepData = {};
	const fullySortedRepData = {};

	Object.keys(repDataByPosition).forEach((pos) => {
		const posData = repDataByPosition[pos];
		let sortedKeys;
		// Check to see if it's not an RFI spot
		if (Array.isArray(posData)) {
			opponentSortedRepData = repDataByPosition;
			return;
		}
		opponentSortedRepData[pos] = {};
		// Check to see if there is just one opponent
		if (!Object.keys(posData)[0].includes(",")) {
			sortedKeys = Object.keys(posData).sort((a, b) => {
				return seats.findIndex((ele) => ele.startsWith(a)) - seats.findIndex((ele) => ele.startsWith(b));
			});
		}
		// If there are multiple opponents
		else {
			sortedKeys = Object.keys(posData).sort((a, b) => {
				a = a.split(",")[0];
				b = b.split(",")[0];
				return seats.findIndex((ele) => ele.startsWith(a)) - seats.findIndex((ele) => ele.startsWith(b));
			});
		}
		sortedKeys.forEach((key) => {
			opponentSortedRepData[pos][key] = posData[key];
		});
	});

	seats.forEach((seat) => {
		if (opponentSortedRepData[seat]) fullySortedRepData[seat] = opponentSortedRepData[seat];
	});

	return fullySortedRepData;
}

// const sortedScenarios = Object.keys(userRepHistory).sort((a, b) => {
// 	return Object.keys(allTrainingScenarios).indexOf(a) - Object.keys(allTrainingScenarios).indexOf(b);
// });
