export default function weightedRandomDeal(hands, pdf) {
	let hand;
	let totalFreq = 0;
	let cdf = {};

	// generate cumulative dist function from prob dist f
	function generateCDF(removeRareHands = true) {
		for (hand in pdf) {
			if (!hands.includes(hand)) continue;
			const freq = parseInt(parseFloat(pdf[hand]) * 1000);
			// // Remove <5% frequency hands
			if (removeRareHands && freq < 50) continue;
			cdf[hand] = totalFreq + freq;
			totalFreq += freq;
		}
	}
	generateCDF();
	if (totalFreq === 0) generateCDF(false);

	// roll a number lower than the sum of all hand frequencies
	const roll = Math.floor(Math.random() * totalFreq);
	for (hand in cdf) if (roll <= cdf[hand]) return hand;

	console.log("something went wrong with weightedRandomDeal. let dev know");
	return "something went wrong with weightedRandomDeal. let dev know";
}
