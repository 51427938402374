const areOptionsValid = (settings, seats, scenarios) => {
	if (!settings) return false;
	else if (!settings.hasOwnProperty("heroPositions") || !settings.hasOwnProperty("oppPositions") || !settings.hasOwnProperty("scenarios")) return false;
	else if (settings.heroPositions.some((pos) => !seats.includes(pos))) return false;
	else if (settings.oppPositions.some((pos) => !seats.includes(pos))) return false;
	else if (settings.scenarios.some((pos) => !scenarios.includes(pos))) return false;
	return true;
};

export default areOptionsValid;
