import { getPositionFromAction } from "../../../helpers";

export default function getPreviousAction(spot) {
	let spotArr = spot.split(",");
	const position = getPositionFromAction(spotArr.pop());
	let index;
	for (var i = spotArr.length - 1; i >= 0; i--) {
		if (position === getPositionFromAction(spotArr[i])) {
			// if (spotArr[i].startsWith(position)) {
			index = i;
			break;
		}
	}
	let prevActionArr = spotArr.slice(0, index + 1);

	if (prevActionArr.length > 0) {
		return prevActionArr.join(",");
	}
}
