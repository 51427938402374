export default function getSeatNamesFromNumberOfSeats(numberOfSeats: number) {
	let seatNames: string[] = [];
	switch (numberOfSeats) {
		case 2:
			seatNames = ["sb", "bb"];
			break;
		case 3:
			seatNames = ["bu", "sb", "bb"];
			break;
		case 4:
			seatNames = ["co", "bu", "sb", "bb"];
			break;
		case 5:
			seatNames = ["utg", "co", "bu", "sb", "bb"];
			break;
		case 6:
			seatNames = ["ep", "mp", "co", "bu", "sb", "bb"];
			break;
		case 7:
			seatNames = ["utg", "ep", "mp", "co", "bu", "sb", "bb"];
			break;
		case 8:
			seatNames = ["utg", "utg+1", "ep", "mp", "co", "bu", "sb", "bb"];
			break;
		case 9:
			seatNames = ["utg", "utg+1", "utg+2", "ep", "mp", "co", "bu", "sb", "bb"];
			break;
	}
	return seatNames;
}
