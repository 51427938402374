import sortActions from "./sortActions";

const getActionCumulativeFrequency = (actionFrequencies, action) => {
	let cumulativeFrequency = 0;
	const actions = sortActions(Object.keys(actionFrequencies));
	for (let i = 0; i < actions.length; i++) {
		if (action == actions[i]) break;
		cumulativeFrequency += actionFrequencies[actions[i]];
	}
	return cumulativeFrequency;
};

export default getActionCumulativeFrequency;
