export default function cleanTreeOptions(rawTreeOptions) {
	const cleanTreeOptions = [];
	const treesObject = {};
	// create an object "zipper" connecting the id's to the metadata
	for (let i = 0; i < rawTreeOptions.length; i = i + 4) {
		treesObject[rawTreeOptions[i]] = { ...rawTreeOptions[i + 1], playerCount: rawTreeOptions[i + 2], gameType: rawTreeOptions[i + 3] };
	}

	// create a sortable array [id, name] to alpha sort the trees
	const sortableOptions = [];
	for (const id in treesObject) sortableOptions.push([id, treesObject[id].treeName]);
	sortableOptions.sort((a, b) => a[1].localeCompare(b[1]));

	// build the options in the sorted order using only the needed properties
	sortableOptions.forEach((option) => {
		const id = option[0];
		const tree = treesObject[id];
		cleanTreeOptions.push({
			id: id,
			rake: tree.rake,
			treeName: tree.treeName,
			playerCount: tree.playerCount,
			gameType: tree.gameType,
		});
	});

	return cleanTreeOptions;
}
