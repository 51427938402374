export default function sortRepDataByTimestamp(scenarioRepData) {
	const sortArray = [];
	for (var rep in scenarioRepData) {
		sortArray.push([rep, Date.parse(scenarioRepData[rep].timestamp).toString()]);
	}
	sortArray.sort((a, b) => a[1] - b[1]);

	let timeSortedScenarioRepData = {};
	sortArray.forEach((rep) => {
		timeSortedScenarioRepData[rep[0]] = scenarioRepData[rep[0]];
	});

	return timeSortedScenarioRepData;
}
