import { capitalize } from "lodash";
import React from "react";
import { currencyFormatter } from "../../../helpers";
import calculateProfit from "../functions/calculateProfit";

export default function SiteInput({
	siteName,
	siteInfo,
	previousBankroll,
	handleSiteUpdate,
	openScreenshotOverlay,
	handleScreenshotRemove,
	openNoteOverlay,
	handleNoteRemove,
	deleteSite,
	dealData,
	activeDeals,
	defaultDeal,
}) {
	// only allows numbers, periods, and commas in an input
	const scrub = (input) => input.replace(/[^\d,.-]/g, "");

	const siteIsNew = previousBankroll === undefined;

	let dealComponent;
	if (activeDeals.length <= 1) {
		const date = dealData[defaultDeal]?.effectiveDate;
		const type = capitalize(dealData[defaultDeal]?.dealType);
		dealComponent = (
			<span className={`site-input-deal-label ${activeDeals.length > 0 ? "" : "display-none"}`}>{`${date} ${type} Deal`}</span>
		);
	} else {
		dealComponent = (
			<select value={siteInfo.deal} onChange={(e) => handleSiteUpdate(e.target.value, siteName, "deal")}>
				{activeDeals.map((id) => {
					const date = dealData[id]?.effectiveDate;
					const type = capitalize(dealData[id]?.dealType);
					return <option value={id}>{`${date} ${type} Deal`}</option>;
				})}
			</select>
		);
	}

	return (
		<div className="site-input-cnt">
			<div className="site-input-header">
				<h3>{siteName}</h3>
				{dealComponent}
				<span className="site-delete" onClick={() => deleteSite(siteName)} title="Delete Site">
					x
				</span>
			</div>
			<div className={`site-input-row`}>
				<label htmlFor="start">{siteIsNew ? "Initial Bankroll" : "Roll at Month Start: "}</label>
				<span>
					$
					<input
						id="start"
						type="text"
						inputMode="numeric"
						value={siteIsNew ? siteInfo.initialBankroll : previousBankroll}
						readOnly={!siteIsNew}
						onChange={(e) => handleSiteUpdate(scrub(e.target.value), siteName, "initialBankroll")}
					/>{" "}
					{`${siteInfo.currency}`}
				</span>
				<button className="hidden" disabled>
					Add Screenshot
				</button>
			</div>
			<div className="site-input-row">
				<label htmlFor="transactions" title="Input deposits as a negative number">
					Withdraws/(-Deposits):{" "}
				</label>
				<span>
					$
					<input
						id="transactions"
						type="text"
						inputMode="numeric"
						value={siteInfo.netTransactions}
						onChange={(e) => handleSiteUpdate(scrub(e.target.value), siteName, "netTransactions")}
					/>{" "}
					{`${siteInfo.currency}`}
				</span>
				<button onClick={() => openScreenshotOverlay(siteName, "transactions")}>Add Screenshot</button>
			</div>
			<div className="site-input-row">
				<label htmlFor="end">Roll at Month End: </label>
				<span>
					$
					<input
						id="end"
						type="text"
						inputMode="numeric"
						value={siteInfo.endBankroll}
						onChange={(e) => {
							handleSiteUpdate(scrub(e.target.value), siteName, "endBankroll");
						}}
					/>{" "}
					{`${siteInfo.currency}`}
				</span>
				<button onClick={() => openScreenshotOverlay(siteName, "bankroll")}>Add Screenshot</button>
			</div>
			<div className="site-input-row site-input-bottom">
				<span>Profit (Loss): </span>
				<span>{`${currencyFormatter.format(
					calculateProfit(!siteIsNew ? previousBankroll : siteInfo.initialBankroll, siteInfo.netTransactions, siteInfo.endBankroll)
				)} ${siteInfo.currency}`}</span>
			</div>
			<div className={`${siteInfo?.screenshots?.transactions?.length ? "screenshot-list-cnt" : "display-none"}`}>
				{siteInfo?.screenshots?.transactions?.map((url, i) => (
					<div className="link-cnt">
						<span className="screenshot-link" onClick={() => window.open(url)} title={url}>
							Transactions Screenshot #{i + 1}
						</span>
						<span
							className="screenshot-delete"
							onClick={() => handleScreenshotRemove(i, siteName, "transactions")}
							title="Remove Screenshot">
							x
						</span>
					</div>
				))}
			</div>
			<div className={`${siteInfo?.screenshots?.bankroll?.length ? "screenshot-list-cnt" : "display-none"}`}>
				{siteInfo?.screenshots?.bankroll?.map((url, i) => (
					<div className="link-cnt">
						<span className="screenshot-link" onClick={() => window.open(url)} title={url}>
							Bankroll Screenshot #{i + 1}
						</span>
						<span className="screenshot-delete" onClick={() => handleScreenshotRemove(i, siteName, "bankroll")} title="Remove Screenshot">
							x
						</span>
					</div>
				))}
			</div>
			<div className={`${siteInfo?.notes ? "screenshot-list-cnt" : "display-none"}`}>
				{siteInfo?.notes?.map((note, i) => (
					<div className="note-cnt">
						<div className="note-label">Note: </div>
						<div className="note-content">{note}</div>
						<div className="note-delete" onClick={() => handleNoteRemove(i, siteName)} title="Delete Note">
							x
						</div>
					</div>
				))}
			</div>
			<div className="note-add-cnt">
				<button className="note-add-button" onClick={() => openNoteOverlay(siteName)}>
					Add Note
				</button>
			</div>
		</div>
	);
}
