import React, { useState } from "react";
import { currencyFormatter, removeDuplicates, removeNonDigits, removeNumbers } from "../../../helpers";
import calculateProfit from "../functions/calculateProfit";
import getPreviousMonth from "../functions/getPreviousMonth";
import SiteSummary from "./SiteSummary";
import SettlementSummary from "./SettlementSummary";
import fx from "../functions/money";
import DealShareSummary from "./DealShareSummary";
import { capitalize } from "lodash";
import getProfitAndNetTransactionsByDeal from "../functions/getProfitAndNetTransactionsByDeal.js";

//props:
//  accountingHistory: {}
//  monthYear: mmYYYY
//  dealStatus: "active/inactive/default"

export default function MonthlyResultSummary({ user, accountingHistory, monthYear, dealStatus, dealData, accountingUsers }) {
	const [currencyToDisplay, setCurrencyToDisplay] = useState("USD");
	const sites = accountingHistory[monthYear].sites;
	const rates = accountingHistory[monthYear].rates;
	fx.base = "USD";
	fx.rates = rates;
	const previousMonth = getPreviousMonth(accountingHistory, monthYear);
	let previousBankrolls = {};
	if (previousMonth) {
		const previousMonthSites = accountingHistory[previousMonth].sites;
		Object.keys(previousMonthSites).forEach((site) => (previousBankrolls[site] = previousMonthSites[site].endBankroll));
	}

	const usedDeals = removeDuplicates(Object.keys(sites).map((site) => sites[site].deal));

	const { totalProfit, profitByDeal, netTransactionsByDeal } = getProfitAndNetTransactionsByDeal(
		sites,
		previousBankrolls,
		currencyToDisplay,
		fx.rates
	);

	const profitColour = totalProfit > 0 ? "profit-green" : totalProfit < 0 ? "profit-red" : "";

	return (
		<div className="monthly-result-summary-ctn">
			<h2 className="month-input-header">{`Results for ${removeNumbers(monthYear)} ${removeNonDigits(monthYear)}`}</h2>
			<div className="monthly-result-sites-ctn">
				{Object.keys(sites).map((site) => {
					if (sites[site].deal !== "settlement") {
						return (
							<SiteSummary
								key={site}
								siteName={site}
								previousBankroll={previousBankrolls[site]}
								initialBankroll={sites[site].initialBankroll ? sites[site].initialBankroll : undefined}
								netTransactions={sites[site].netTransactions}
								endBankroll={sites[site].endBankroll}
								currency={sites[site].currency}
								screenshots={sites[site].screenshots}
								notes={sites[site].notes}
								dealLabel={`${dealData[sites[site].deal].effectiveDate} ${capitalize(dealData[sites[site].deal].dealType)} Deal`}
							/>
						);
					} else {
						return (
							<SettlementSummary
								siteName={site}
								settlementAmount={sites[site].netTransactions}
								currency={sites[site].currency}
								screenshots={sites[site].screenshots}
								notes={sites[site].notes}
							/>
						);
					}
				})}
			</div>
			<div className="month-summary-cnt">
				<div className="month-result-total-ctn">
					<span className="month-result-total-start">Total Profit (Loss): </span>
					<span className={`month-result-total-mid ${profitColour}`}>{currencyFormatter.format(totalProfit)}</span>
					<select className="month-result-total-end" value={currencyToDisplay} onChange={(e) => setCurrencyToDisplay(e.target.value)}>
						{Object.keys(rates).map((cur) => (
							<option key={cur} value={cur}>
								{cur}
							</option>
						))}
					</select>
				</div>
				<div className="exchange-rate-ctn">
					Exchange Rates
					{Object.keys(rates).map((cur, i) => (
						<div key={`${cur}${i}`}>
							{cur} : {(rates[cur] / rates[currencyToDisplay]).toFixed(3)}
						</div>
					))}
				</div>
			</div>
			<div className="deal-share-cnt" hidden={!Object.keys(dealStatus).length}>
				{usedDeals.map((dealid) => {
					if (dealid !== "settlement") {
						return (
							<DealShareSummary
								key={dealid}
								user={user}
								dealData={dealData[dealid]}
								dealProfit={profitByDeal[dealid]}
								dealTransactions={netTransactionsByDeal[dealid]}
								currency={currencyToDisplay}
								accountingUsers={accountingUsers}
							/>
						);
					} else {
						return (
							<DealShareSummary
								key={dealid}
								user={user}
								dealData={{ subject: user, dealType: "settlement", shareholderBreakdown: {} }}
								dealProfit={profitByDeal[dealid]}
								dealTransactions={netTransactionsByDeal[dealid]}
								currency={currencyToDisplay}
								accountingUsers={accountingUsers}
							/>
						);
					}
				})}
			</div>
		</div>
	);
}
