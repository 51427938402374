import React from "react";
import calculateProfit from "../functions/calculateProfit";
import formatDollarAmount from "../functions/formatDollarAmount";

export default function SiteSummary({
	siteName,
	previousBankroll,
	initialBankroll,
	netTransactions,
	endBankroll,
	currency,
	screenshots,
	notes,
	dealLabel,
}) {
	const profit = calculateProfit(previousBankroll ? previousBankroll : initialBankroll, netTransactions, endBankroll);
	const profitColour = profit == 0 ? "profit-yellow" : profit > 0 ? "profit-green" : "profit-red";

	return (
		<div className="site-summary-cnt">
			<div className="site-summary-header">
				<h3>{siteName}</h3>
				<span className="site-summary-deal-label">{dealLabel}</span>
			</div>
			<div className="site-summary-row">
				<span>{previousBankroll ? "Roll at Month Start:" : "Initial Bankroll"}</span>
				<span>
					{formatDollarAmount(previousBankroll ? previousBankroll : initialBankroll ? initialBankroll : 0.0)} {currency}
				</span>
			</div>
			<div className="site-summary-row">
				<span>Withdraws/(-Deposits): </span>
				<span>
					{formatDollarAmount(netTransactions)} {currency}
				</span>
			</div>
			<div className="site-summary-row">
				<span>Roll at Month End: </span>
				<span>
					{formatDollarAmount(endBankroll)} {currency}
				</span>
			</div>
			<div className="site-summary-row site-summary-bottom">
				<span>Profit (Loss): </span>
				<span className={profitColour}>{`${formatDollarAmount(profit)} ${currency}`}</span>
			</div>
			<div className={`${screenshots?.transactions ? "screenshot-list-cnt" : "display-none"}`}>
				{screenshots?.transactions?.map((url, i) => (
					<div className="link-cnt">
						<span className="screenshot-link" onClick={() => window.open(url)} title={url}>
							Transactions Screenshot #{i + 1}
						</span>
					</div>
				))}
			</div>
			<div className={`${screenshots?.bankroll ? "screenshot-list-cnt" : "display-none"}`}>
				{screenshots?.bankroll?.map((url, i) => (
					<div className="link-cnt">
						<span className="screenshot-link" onClick={() => window.open(url)} title={url}>
							Bankroll Screenshot #{i + 1}
						</span>
					</div>
				))}
			</div>
			<div className={`${notes ? "screenshot-list-cnt" : "display-none"}`}>
				{notes?.map((note, i) => (
					<div className="link-cnt">
						<span className="note-label">Note:</span>
						<span className="note-content">{note}</span>
					</div>
				))}
			</div>
		</div>
	);
}
