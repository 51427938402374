import React, { useEffect, useState } from "react";
import base from "../../../base";
import StudentResult from "./StudentResult";
import { removeNonDigits, removeNumbers } from "../../../helpers";
import { isEmpty } from "lodash";
import { nanoid } from "nanoid";

// MAYBE TODO: do a check to see if the period that has been clicked is BEFORE any results have ever been posted
// ie. if someone clicks on Jan 2022, before the accounting app was a thing, show "No Students here"

// SIMILAR, MAYBE MORE IMPORTANT TODO: if a student is NO LONGER someone's student, don't show 'em - could do a check in the Student Result
// if the total deal shares are nonexistant or 0 then to just not render the goods

export default function StudentSummary({ user, monthYear, accountingUsers }) {
	const [loading, setLoading] = useState(true);
	const [currencyToDisplay, setCurrencyToDisplay] = useState("USD");

	const [userShares, setUserShares] = useState(null);
	const [sharesDealInfo, setSharesDealInfo] = useState(null);
	const [studentDeals, setStudentDeals] = useState(null);
	const [studentAccountingHistories, setStudentAccountingHistories] = useState(null);

	useEffect(() => {
		getStudentAccountingHistory();
	}, []);

	const getStudentAccountingHistory = async () => {
		// get all the deals that user has a share of
		const userShares = await base.fetch(`accountingDeals/users/${user}/shares`, { context: this });
		if (isEmpty(userShares)) {
			setLoading(false);
			return;
		} // there are no deals that user has shares of
		const dataPromises = [];
		const sharesDealInfo = {};
		Object.keys(userShares).forEach((dealid) => {
			// get all deals, not just active ones, because a deal may have become inactive since the summary period
			// if (userShares[dealid] === "inactive") return;
			dataPromises.push(dealid);
			dataPromises.push(base.fetch(`accountingDeals/deals/${dealid}`, { context: this }));
		});
		const dataRes = await Promise.all(dataPromises);
		for (let i = 0; i < dataRes.length; i = i + 2) sharesDealInfo[dataRes[i]] = dataRes[i + 1];
		// get all the subjects ie. students of the user
		const studentDeals = {};
		Object.keys(sharesDealInfo).forEach((deal) => {
			if (studentDeals.hasOwnProperty(sharesDealInfo[deal].subject)) studentDeals[sharesDealInfo[deal].subject].push(deal);
			else studentDeals[sharesDealInfo[deal].subject] = [deal];
			// if (!students.includes(sharesDealInfo[deal].subject)) students.push(sharesDealInfo[deal].subject);
		});
		// get the month's accounting history for each Student
		const studentResultPromises = [];
		const studentAccountingHistories = {};
		Object.keys(studentDeals).forEach((student) => {
			studentResultPromises.push(student);
			studentResultPromises.push(base.fetch(`accountingHistory/${student}`, { context: this }));
		});
		const studentResultRes = await Promise.all(studentResultPromises);
		for (let i = 0; i < studentResultRes.length; i = i + 2) studentAccountingHistories[studentResultRes[i]] = studentResultRes[i + 1];
		setUserShares(userShares);
		setSharesDealInfo(sharesDealInfo);
		setStudentDeals(studentDeals);
		setStudentAccountingHistories(studentAccountingHistories);
		setLoading(false);
	};

	if (loading) return <></>;
	if (userShares === null)
		return (
			<div>
				<h2 className="month-input-header">{`Student Summary for ${removeNumbers(monthYear)} ${removeNonDigits(monthYear)}`}</h2>
				<h4 className="student-msg">No students found</h4>
			</div>
		);
	return (
		<div>
			<h2 className="month-input-header">{`Student Summary for ${removeNumbers(monthYear)} ${removeNonDigits(monthYear)}`}</h2>
			<div className="student-results-cnt">
				{Object.keys(studentAccountingHistories).map((student) => {
					const dealsInfo = Object.fromEntries(Object.entries(sharesDealInfo).filter((deal) => studentDeals[student].includes(deal[0])));
					return (
						<StudentResult
							key={nanoid(3)}
							user={user}
							studentName={accountingUsers[student]}
							monthYear={monthYear}
							studentAccountingHistory={studentAccountingHistories[student]}
							dealsInfo={dealsInfo}
							shareholderDealList={Object.keys(userShares)}
							currency={currencyToDisplay}
						/>
					);
				})}
			</div>
		</div>
	);
}
