import base from "../../base";
import userIsAdmin from "../Shared/functions/userIsAdmin";

export default async function deleteTree(uid, treeID, deleteReps = false, trackDeletion = true) {
	if (!(await userIsAdmin(uid))) return;
	console.log("Confirm Tree Deletion...");
	if (!confirm("Are you sure you want to delete this tree?")) return;

	// saving to a list of deleted trees, to be able to trace back saved reps to a tree
	if (trackDeletion) {
		const treeName = await base.fetch(`trees/${treeID}/metadata/treeName`, { context: this });
		base.post(`deletedTrees/${treeID}`, { data: treeName });
	}

	console.log(`trackDeletion: ${trackDeletion}`);
	console.log(`deleteReps: ${deleteReps}`);

	if (deleteReps) base.remove(`reps/${treeID}`);

	base.remove(`trees/${treeID}`);
	base.remove(`publicTrees/${treeID}`);

	const treePermissions = await base.fetch(`treePermissions`, { context: this });

	Object.keys(treePermissions).forEach((user) => {
		if (treePermissions[user].hasOwnProperty(treeID)) base.remove(`treePermissions/${user}/${treeID}`);
	});

	console.log("Tree Deleted");
}

// MKT-ExtD56D6 - 20bb 6max
